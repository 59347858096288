import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  updateEmail,
  updateProfile,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  updateDoc,
  getDoc,
  doc,
  collection,
  deleteField,
  Timestamp,
} from "firebase/firestore";
import useLocalStorage from "helpers/useLocalStorage";
import { useDeviceData } from "react-device-detect";
import { Outlet } from "react-router-dom";

//CREATE AUTH CONTEXT
const AuthContext = createContext();

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const AuthContextProvider = ({ children }) => {
  const { browser, device, os } = useDeviceData();

  const [user, setUser] = useState(null);
  const [authContextLoaded, setAuthContextLoaded] = useState(false);
  const [canLogin, setCanLogin] = useState();
  const [deviceLimit, setDeviceLimit] = useState(0);
  const [currentDevices, setCurrentDevices] = useState({});
  const [isRemovingDevice, setIsRemovingDevice] = useState(false);

  const [deviceID, setDeviceID] = useLocalStorage("deviceID", null);
  if (deviceID == null)
    setDeviceID(doc(collection(getFirestore(), "randomIDGenerator")).id);

  const subdomain = useMemo(() => window.location.hostname.split(".")[0], []);

  //MONITOR USER STATUS
  useEffect(() => {
    // setAuthContextLoaded(false);
    const auth = getAuth();
    return onAuthStateChanged(auth, async (firUser) => {
      if (firUser) {
        setAuthContextLoaded(false);

        //Set the user type from custom claims
        const idTokenResult = await firUser.getIdTokenResult();
        firUser.userAccess = idTokenResult.claims.user_access;

        //Check if below device limit, or deviceID exists in the DB
        const userDoc = await getDoc(doc(getFirestore(), "users", firUser.uid));
        firUser.preferredCurrency = userDoc.data().preferredCurrency;
        const { maxDevices } = userDoc.data();
        const registeredDevices = {};
        Object.entries(
          ["local", "staging"].includes(
            process.env.REACT_APP_USER_ENVIRONMENT,
          ) && userDoc.data().devicesStaging != null
            ? userDoc.data().devicesStaging
            : userDoc.data().devicesProduction != null
              ? userDoc.data().devicesProduction
              : {},
        ).forEach(([deviceID, deviceData]) => {
          registeredDevices[deviceID] = {
            ...deviceData,
            lastUsed: deviceData.lastUsed?.toDate(),
          };
        });

        setDeviceLimit(maxDevices);
        setCurrentDevices(registeredDevices);
        const registeredDeviceIDs = Object.keys(registeredDevices || {});
        if (
          registeredDeviceIDs.length < maxDevices ||
          registeredDeviceIDs.includes(deviceID)
        ) {
          //If not at limit, or deviceID exists, write deviceID to DB and set that user can login
          //Writing this to the DB will not double up as its using the arrayUnion function
          await updateDoc(doc(getFirestore(), "users", firUser.uid), {
            [`devices${
              ["local", "staging"].includes(
                process.env.REACT_APP_USER_ENVIRONMENT,
              )
                ? "Staging"
                : "Production"
            }.${deviceID}`]: {
              device: {
                vendor: device.vendor || "unknown",
                model: device.model || "unknown",
              },
              os: {
                name: os.name || "unknown",
                version: os.version || "unknown",
              },
              browser: { name: browser.name || "unknown" },
              lastUsed: Timestamp.fromDate(new Date()),
            },
          });
          //Set that user can login and set the user
          setCanLogin(true);
          setUser(firUser);
          setAuthContextLoaded(true);
        } else {
          //If at limit, and deviceID does not exist in the DB, refuse signin and logout the user
          setCanLogin(false);
          setAuthContextLoaded(true);
          if (!isRemovingDevice) {
            await signOut(getAuth());
          }
          // toast.error(
          //   <div>
          //     <p>{"You have reached the limit for registered devices."}</p>
          //     <p>{"You must logout on one of those devices to login here."}</p>
          //   </div>,
          //   { autoClose: 8000 }
          // );
        }
      } else {
        setUser(null);
        //Waiting here to give the Login Component a chance to re-render and stop loading animation before the below reset
        // await new Promise((resolve) => {
        //   setTimeout(resolve, 1000);
        // });
        setCanLogin();
        setAuthContextLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceID, isRemovingDevice]);

  //MONITOR USER DOC
  // useEffect(() => {
  //   if (!authContextLoaded || user == null || !canLogin) return;
  //   const unsub = onSnapshot(doc(getFirestore(), "users", user.uid), (doc) => {
  //     console.log(doc.data());
  //     if (!doc.data().registeredDevices.includes(deviceID)) {
  //       setCanLogin(false);
  //       setUser(null);
  //       signOut(getAuth());
  //     }
  //     return () => unsub();
  //   });
  // }, [authContextLoaded, deviceID, user, canLogin]);

  //SIGNIN USER
  const login = useCallback(async (email, password) => {
    //Returns a User or an Error if no User
    const userCredential = await signInWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    return userCredential.user;
  }, []);

  //SIGNOUT USER
  const logout = useCallback(async () => {
    //Remove the deviceID on explicit logout
    await updateDoc(doc(getFirestore(), "users", user.uid), {
      [`devices${
        ["local", "staging"].includes(process.env.REACT_APP_USER_ENVIRONMENT)
          ? "Staging"
          : "Production"
      }.${deviceID}`]: deleteField(),
    });
    return signOut(getAuth());
  }, [deviceID, user?.uid]);

  //REAUTHENTICATE USER
  const reauthenticateUser = async (password) => {
    return reauthenticateWithCredential(
      user,
      EmailAuthProvider.credential(user.email, password),
    );
  };

  //CHANGE PASSWORD
  const changePassword = async (newPassword) => {
    await updateDoc(doc(getFirestore(), "users", user.uid), {
      [`devices${
        subdomain === "staging" ||
        subdomain === "localhost" ||
        subdomain === "scotts-macbook-pro-2023" ||
        subdomain === "192"
          ? "Staging"
          : "Production"
      }`]: {
        [`${deviceID}`]: {
          device: {
            vendor: device.vendor || "unknown",
            model: device.model || "unknown",
          },
          os: {
            name: os.name || "unknown",
            version: os.version || "unknown",
          },
          browser: { name: browser.name || "unknown" },
          lastUsed: Timestamp.fromDate(new Date()),
        },
      },
    });
    return updatePassword(getAuth().currentUser, newPassword);
  };

  //CHANGE USER DETAILS
  const updateUserDetails = async (name, email) => {
    try {
      if (email !== user.email) {
        await updateDoc(doc(getFirestore(), "users", user.uid), {
          [`devices${
            subdomain === "staging" ||
            subdomain === "localhost" ||
            subdomain === "scotts-macbook-pro-2023" ||
            subdomain === "192"
              ? "Staging"
              : "Production"
          }`]: {
            [`${deviceID}`]: {
              device: {
                vendor: device.vendor || "unknown",
                model: device.model || "unknown",
              },
              os: {
                name: os.name || "unknown",
                version: os.version || "unknown",
              },
              browser: { name: browser.name || "unknown" },
              lastUsed: Timestamp.fromDate(new Date()),
            },
          },
        });
      }
      await updateEmail(getAuth().currentUser, email);
      await updateProfile(getAuth().currentUser, { displayName: name });
      await updateDoc(doc(getFirestore(), "users", user.uid), {
        name: name,
        email: email,
      });
      return true;
    } catch (error) {
      throw error;
    }
  };

  //SEND PASSWORD RESET EMAIL
  const sendPasswordReset = async (email) => {
    return sendPasswordResetEmail(getAuth(), email);
  };

  const onDeleteDeviceIdAndLogin = async (deviceID, email, password) => {
    setIsRemovingDevice(true);
    const user = await login(email, password);
    await updateDoc(doc(getFirestore(), "users", user.uid), {
      [`devices${
        ["local", "staging"].includes(process.env.REACT_APP_USER_ENVIRONMENT)
          ? "Staging"
          : "Production"
      }.${deviceID}`]: deleteField(),
    });
    setIsRemovingDevice(false);
  };

  return (
    <AuthContext.Provider
      value={{
        authContextLoaded,
        canLogin,
        deviceLimit,
        currentDevices,
        onDeleteDeviceIdAndLogin,
        user,
        updateUserDetails,
        login,
        logout,
        sendPasswordReset,
        changePassword,
        reauthenticateUser,
      }}
    >
      {/* {children} */}
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useAuth = () => useContext(AuthContext);
