import styled from "styled-components/macro";
import { COLORS, FONTSIZE } from "helpers/designConstants";

const StandardLayout = ({
  leftMenuContent,
  isMenuOpen,
  setIsMenuOpen,
  children,
}) => {
  return (
    <Page>
      <Header>
        <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <img src="/menu.svg" alt="menu" />
        </MenuButton>
        <Logo>
          <img src="/stormtech_horizontal.svg" alt="Stormtech Logo" />
        </Logo>
        <HeaderRight></HeaderRight>
      </Header>
      <ContentWrapper>
        <LeftMenuWrapper isOpen={isMenuOpen}>
          <MenuHeader>
            <CloseButton onClick={() => setIsMenuOpen(false)}>
              <img src="/close_button.svg" alt="close button" />
            </CloseButton>
          </MenuHeader>
          {leftMenuContent}
          <AppVersion>{`v${process.env.REACT_APP_VERSION}`}</AppVersion>
        </LeftMenuWrapper>
        <MainContentWrapper>{children}</MainContentWrapper>
      </ContentWrapper>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  padding: 5px 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    height: 40px;
    border-bottom: 1px solid ${COLORS.stormtechOrange};
  }
`;

const MenuButton = styled.div`
  height: 100%;
  width: 40px;
  /* display: flex; */
  /* justify-content: flex-start; */

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Logo = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    padding: 5px 10px;
    justify-content: flex-start;
  }

  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const HeaderRight = styled.div`
  width: 40px;
`;

const LeftMenuWrapper = styled.div`
  height: 100%;
  /* min-width: 250px;
  max-width: 500px; */
  width: 300px;
  padding-bottom: 15px;
  background: ${COLORS.stormtechGrey};
  display: grid;
  grid-template-rows: auto 1fr;
  box-shadow: 3px 0 5px -1px #aaa;
  border-radius: 0 8px 8px 0;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-120%)"};
  position: absolute;
  top: 0;
  z-index: 20;
  overflow-y: hidden;

  @media (min-width: 1024px) {
    transform: translateX(0);
    /* width: auto; */
    position: relative;
    /* padding-top: 10px; */
    border-radius: 0;
    box-shadow: unset;
  }

  transition: 0.3s all;
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;

  @media (min-width: 1024px) {
    height: 0px;
    padding: 0px;
    visibility: hidden;
  }
`;

const CloseButton = styled.div`
  height: 20px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow-y: hidden;

  @media (min-width: 1024px) {
    grid-template-columns: auto 1fr;
  }
`;

const MainContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

const AppVersion = styled.div`
  font-size: ${FONTSIZE.xsmall};
  color: ${COLORS.text};
  position: absolute;
  bottom: 2px;
  right: 5px;
`;

export default StandardLayout;
