import LeftMenuNavSection from "components/LeftMenuNavSection";
import FacetSection from "./FacetSection";
import { useProjectSearch } from "database/useProjectSearch";

const LeftMenu = ({ onMenuClose, onCreateNewProject }) => {
  const {
    searchResults,
    filters,
    toggleFilter,
    currentFacetSearch,
    setCurrentFacetSearch,
  } = useProjectSearch();

  return (
    <div className="grid h-full grid-rows-[1fr] overflow-hidden">
      <div className="grid h-full grid-rows-[auto_auto_1fr] gap-5 overflow-hidden">
        <div className="">
          <LeftMenuNavSection
            location={{
              name: "Main",
              url: "/private/search",
              direction: "left",
            }}
          />
        </div>
        <div className="px-2">
          <button
            className="font-bold text-white hover:text-st-orange"
            onClick={() => {
              onMenuClose();
              onCreateNewProject();
            }}
          >
            + NEW PROJECT
          </button>
        </div>
        <div className="h-full overflow-auto">
          <div className="grid h-full min-h-[500px] grid-rows-[0.5fr_0.5fr]">
            <div className="h-full overflow-y-hidden px-2">
              <FacetSection
                title="Distributors"
                facetField="distributor"
                data={
                  searchResults.facetCounts.find(
                    (facet) => facet.field_name === "distributor",
                  )?.counts
                }
                currentFilter={filters.distributor?.[0]}
                onSelectFacet={(value) => {
                  if (!filters.distributor?.[0]) onMenuClose();
                  toggleFilter("distributor", value);
                }}
                currentFacetSearch={currentFacetSearch}
                onFacetSearchTermChanged={setCurrentFacetSearch}
              />
            </div>
            <div className="h-full overflow-y-hidden px-2">
              <FacetSection
                title="Reps"
                facetField="rep"
                data={
                  searchResults.facetCounts.find(
                    (facet) => facet.field_name === "rep",
                  )?.counts
                }
                currentFilter={filters.rep?.[0]}
                onSelectFacet={(value) => {
                  filters.rep ?? onMenuClose();
                  toggleFilter("rep", value);
                }}
                currentFacetSearch={currentFacetSearch}
                onFacetSearchTermChanged={setCurrentFacetSearch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
