import SearchPage from "pages/searchPage/SearchPage";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import LoginPage from "components/LoginPage";
import AuthContextProvider from "database/useAuth";
import DatabaseReaderContextProvider from "database/useDatabaseReader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHasFocus } from "helpers/useHasFocus";
import { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import LandingPage from "pages/landingPage/LandingPage";
import { ProjectsMainPage } from "pages/projectsMainPage/ProjectsMainPage";
import { ProjectBuilderPage } from "pages/projectBuilderPage/ProjectBuilderPage";
import { ProjectViewerPage } from "pages/projectViewerPage/ProjectViewerPage";
import ProjectSearchContextProvider from "database/useProjectSearch";
import { NotFound } from "pages/notFound/NotFound";

const App = () => {
  const hasFocus = useHasFocus();

  useEffect(() => {
    if (hasFocus) {
      // console.log("Warm the function");
      const getInventory = httpsCallable(getFunctions(), "onGetInventory");
      getInventory({ ping: true }).then((result) => {
        // console.log(result.data, "function warmed");
      });
    }
  }, [hasFocus]);

  return (
    <BrowserRouter>
      {/* <AuthContextProvider> */}
      {/* <DatabaseReaderContextProvider> */}
      <Routes>
        <Route element={<AuthContextProvider />}>
          <Route element={<DatabaseReaderContextProvider />}>
            <Route index element={<LandingPage />} />
            <Route path="/private/*" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/private" element={<ProtectedRoute />}>
              <Route path="search" element={<SearchPage />} />
              <Route element={<ProjectSearchContextProvider />}>
                <Route path="projects" element={<ProjectsMainPage />} />
              </Route>
              <Route
                path="projects/builder/:projID/"
                element={<ProjectBuilderPage />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="/public/sharing/:projID" element={<ProjectViewerPage />} />
        <Route path="/not_found" element={<NotFound />} />
        <Route path="/public/*" element={<Navigate to="/not_found" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        // draggable
        pauseOnHover
      />
      {/* </DatabaseReaderContextProvider> */}
      {/* </AuthContextProvider> */}
    </BrowserRouter>
  );
};

export default App;
