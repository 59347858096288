import {
  COLORS,
  CONSTANTS,
  FONTWEIGHT,
  FONTSIZE,
} from "helpers/designConstants";
import React from "react";
import styled from "styled-components";
import { useDatabaseReader } from "database/useDatabaseReader";

const WarehousesSection = ({ selectedWarehouse, onSelectedWarehouse }) => {
  const { warehouses } = useDatabaseReader();
  return (
    <Wrapper>
      <Title>Warehouses</Title>
      <WarehouseList>
        <WarehouseListItem
          selected={selectedWarehouse === null}
          onClick={() => onSelectedWarehouse(null)}
        >
          ALL
        </WarehouseListItem>
        {warehouses.map((warehouse) => (
          <WarehouseListItem
            key={warehouse.letterCode}
            selected={selectedWarehouse === warehouse}
            onClick={() => onSelectedWarehouse(warehouse)}
          >
            {warehouse.name}
          </WarehouseListItem>
        ))}
      </WarehouseList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0px;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid white;
  font-size: ${FONTSIZE.medium};
`;

const WarehouseList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 5px;
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.medium};
  color: white;
`;

const WarehouseListItem = styled.li`
  padding: 10px 10px;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${({ selected }) => (selected ? "white" : "inherit")};
  color: ${({ selected }) => (selected ? COLORS.stormtechGrey : "white")};
  font-weight: ${({ selected }) => selected && FONTWEIGHT.heavy};
  cursor: pointer;
  user-select: none;

  :hover {
    font-weight: ${({ selected }) => !selected && FONTWEIGHT.heavy};
  }
`;

export default WarehousesSection;
