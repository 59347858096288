import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "database/useAuth";
import styled from "styled-components/macro";
import useNavigation from "helpers/useNavigation";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { Button } from "components/standardElements";
import LabelledTextInput from "./LabelledTextInput";
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import Loading from "./Loading";
import DeviceSelectionModal from "./DeviceSelectionModal";

const Errors = {
  "auth/invalid-email": "Invalid Email Format",
  "auth/user-not-found": "User Not Found",
  "auth/wrong-password": "Incorrect Password",
  "auth/internal-error": "Please fill in both fields",
  "auth/user-disabled": "The user has been disabled",
};

const LoginPage = () => {
  const {
    login,
    authContextLoaded,
    canLogin,
    currentDevices,
    onDeleteDeviceIdAndLogin,
    user,
    sendPasswordReset,
  } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] =
    useState(false);
  const [showDeviceSelectionModal, setShowDeviceSelectionModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [navigate] = useNavigation();

  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    if (!authContextLoaded) {
      setLoading(true);
      return;
    }

    if (authContextLoaded && user == null) {
      setLoading(false);
      return;
    }

    if (authContextLoaded && user != null && canLogin) {
      navigate("/search");
      return;
    }
  }, [authContextLoaded, canLogin, navigate, user]);

  useEffect(() => {
    if (authContextLoaded && canLogin === false) {
      setShowDeviceSelectionModal(true);
      // setLoading(false);
    }
  }, [authContextLoaded, canLogin]);

  const onTextInputChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  const submitLoginCredentials = async (e) => {
    e.preventDefault();
    emailRef.current.blur();
    passwordRef.current.blur();
    setLoading(true);
    try {
      //User is returned on login but don't need it here
      await login(email, password);
    } catch (error) {
      toast.error(Errors[error.code]);
      setLoading(false);
    }
  };

  const onConfirmPasswordReset = async () => {
    setShowConfirmPasswordReset(false);

    try {
      await toast.promise(sendPasswordReset(email), {
        pending: "Sending Password Reset...",
        success: "Password Reset Sent!",
      });
    } catch (error) {
      toast.error(Errors[error.code]);
    }
  };

  return (
    <ContentWrapper>
      <LogoHeader>
        <Image src="/stormtech.svg" />
      </LogoHeader>
      <StyledForm onSubmit={submitLoginCredentials}>
        <InputsWrapper>
          <EmailPasswordInput
            ref={emailRef}
            marginBottom="10px"
            type="email"
            labelText={"Email"}
            id="email"
            onChange={onTextInputChange}
            value={email}
          />
          {}
          <EmailPasswordInput
            ref={passwordRef}
            marginBottom="15px"
            type="password"
            labelText={"Password"}
            id="password"
            onChange={onTextInputChange}
            value={password}
          />
          <StyledButton
            type="submit"
            disabled={email.length === 0 || password.length === 0}
          >
            GO
          </StyledButton>
        </InputsWrapper>
        <PasswordReset
          onClick={
            email !== ""
              ? () => setShowConfirmPasswordReset(true)
              : () => toast.error("Please enter your email")
          }
        >
          {"Forgot Password?"}
        </PasswordReset>
      </StyledForm>
      <ConfirmModal
        show={showConfirmPasswordReset}
        onClose={() => setShowConfirmPasswordReset(false)}
        onConfirm={onConfirmPasswordReset}
        closeButtonHidden={true}
        disableCloseOnOutsideClick={true}
        title={"Confirm Password Reset"}
        message={`Do you wish to send a password reset email to: ${email}?`}
        buttonTitle={"Send Reset"}
      />
      <DeviceSelectionModal
        show={showDeviceSelectionModal}
        onClose={() => setShowDeviceSelectionModal(false)}
        closeButtonHidden
        disableCloseOnOutsideClick
        title={"Device/Browser Limit Reached"}
        currentDevices={currentDevices}
        onSelectDeviceID={(deviceID) => {
          onDeleteDeviceIdAndLogin(deviceID, email, password);
          setShowDeviceSelectionModal(false);
        }}
      />
      <Loading isLoading={loading} />
    </ContentWrapper>
  );
};

export default LoginPage;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  align-items: center;
  /* max-width: 95%; */
  height: 100vh;
`;

const LogoHeader = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
  font-size: ${FONTSIZE.xlarge};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const Image = styled.img`
  width: 300px;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    width: 45vw;
  }
`;

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputsWrapper = styled.div`
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  @media screen and (max-height: 700px) {
    padding-top: 0vh;
  }
`;

const EmailPasswordInput = styled(LabelledTextInput)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  width: 75px;
`;

const PasswordReset = styled.div`
  font-size: ${FONTSIZE.small};
  color: ${COLORS.text};
  padding-top: 20px;
  text-decoration: underline;
  cursor: pointer;
`;
