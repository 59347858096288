import React from "react";
import styled from "styled-components/macro";

import Modal from "components/Modal";
import { Button } from "components/standardElements";

import { COLORS, CONSTANTS, FONTWEIGHT } from "helpers/designConstants";

const DeviceSelectionModal = ({
  show,
  isDestructive,
  onClose,
  onConfirm,
  title,
  currentDevices,
  onSelectDeviceID,
  ...props
}) => {
  return (
    <Modal title={title} show={show} onClose={onClose} {...props}>
      <Container>
        <MessageWrapper>
          <span>
            In order to login, please select a device / browser below to logout.
          </span>
        </MessageWrapper>
        <DeviceListWrapper>
          {Object.entries(currentDevices || {}).map(
            ([deviceID, deviceDetails]) => (
              <ListItem
                key={deviceID}
                onClick={() => onSelectDeviceID(deviceID)}
              >
                <LineWrapper>
                  <LineTitle>Browser:</LineTitle>
                  <LineData>{`${deviceDetails.browser.name}`}</LineData>
                </LineWrapper>
                <LineWrapper>
                  <LineTitle>Device:</LineTitle>
                  <LineData>{`${`${deviceDetails.device.vendor} ${deviceDetails.device.model}`}`}</LineData>
                </LineWrapper>
                <LineWrapper>
                  <LineTitle>OS:</LineTitle>
                  <LineData>{`${deviceDetails.os.name} ${deviceDetails.os.version}`}</LineData>
                </LineWrapper>
                <LineWrapper>
                  <LineTitle>Last Used:</LineTitle>
                  <LineData>{`${
                    deviceDetails.lastUsed?.toLocaleDateString("en-ca", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }) || "Unknown"
                  }`}</LineData>
                </LineWrapper>
              </ListItem>
            ),
          )}
        </DeviceListWrapper>
        <ButtonWrapper>
          <StyledButton cancel onClick={onClose} type="button">
            {"Cancel"}
          </StyledButton>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 5px;
  max-width: 400px;
  max-height: 60vh;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  @media (max-width: 500px) {
    max-width: unset;
    /* height: 100%; */
    max-height: unset;
    padding-bottom: 70px;
  }
`;

const MessageWrapper = styled.div`
  text-align: center;
  color: ${COLORS.text};
  text-transform: uppercase;
`;

const DeviceListWrapper = styled.div`
  margin: 20px 0;
  padding: 0px 0px;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const ListItem = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: ${COLORS.tertiary};
  /* :first-child {
    padding: 0 20px 10px 20px;
  }
  :last-child {
    padding: 10px 20px 0px 20px;
  } */
  :not(:last-child) {
    border-bottom: 1px solid ${COLORS.secondary};
  }
  :hover {
    background-color: ${COLORS.secondary};
  }
  cursor: pointer;
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LineTitle = styled.div``;

const LineData = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin: 15px 5px 0 5px;
`;

export default DeviceSelectionModal;
