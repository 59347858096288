export const baseColorCodes = {
  RED: "01",
  YELLOW: "03",
  ORANGE: "02",
  BLACK: "04",
  BROWN: "05",
  BLUE: "06",
  GREEN: "07",
  GRAY: "08",
  PURPLE: "09",
  WHITE: "10"
};

export const colorCodeRanking = [
  "04",
  "08",
  "06",
  "01",
  "10",
  "07",
  "02",
  "03",
  "05",
  "09"
];

export const allColorCodes = {
  BLACK: "04",
  INDIGO: "06",
  "MARINE BLUE": "06",
  "TRUE RED": "01",
  CHARCOAL: "08",
  NAVY: "06",
  "AZURE BLUE": "06",
  "BRIGHT RED": "01",
  GRAPHITE: "08",
  MOSS: "07",
  "CARBON MIX": "08",
  DOLPHIN: "08",
  CARBON: "08",
  "DOLPHIN HEATHER": "08",
  "INDIGO HEATHER": "06",
  "BLUE HEATHER": "06",
  "CARBON HEATHER": "08",
  "KIWI HEATHER": "07",
  "RED HEATHER": "01",
  "CHARCOAL HEATHER": "08",
  "GRANITE HEATHER": "08",
  "GREY HEATHER": "08",
  "NAVY HEATHER": "06",
  MIDNIGHT: "06",
  "FLAME RED": "01",
  CHIPOTLE: "02",
  "TREETOP GREEN": "07",
  "ELECTRIC BLUE": "06",
  EARTH: "07",
  WHITE: "10",
  GREY: "08",
  PEAT: "08000001",
  GRANITE: "08",
  RED: "01",
  "FOREST CAMO": "07",
  "WINTER CAMO": "08",
  "ASH HEATHER": "08000001",
  TAUPE: "05",
  MINERAL: "05",
  "STEEL BLUE": "06",
  OCEAN: "06",
  TAN: "03",
  PEWTER: "08",
  PINE: "07",
  FOREST: "07",
  ZINC: "08",
  "BOLD RED": "01",
  "OCEAN BLUE": "06",
  "CHARCOAL TWILL": "08",
  "CLASSIC BLUE": "06",
  DENIM: "06",
  SAGE: "07",
  "BLACK HEATHER": "04",
  "OATMEAL HEATHER": "05",
  "RED PLAID": "01",
  "CARBON PLAID": "08",
  "NAVY PLAID": "06",
  BLUE: "06",
  YELLOW: "03",
  "MOSSY OAK CAMO": "05",
  "SPORT RED": "01",
  ROYAL: "06",
  SUNDANCE: "03",
  "DK NAVY HEATHER": "06",
  "HEATHER GREY": "08",
  "CLASSIC BLUE HEATHER": "06",
  SILVER: "08",
  "TRUE NAVY": "06",
  SCARLET: "01",
  "GRAPHITE HEATHER": "08",
  "GARDEN GREEN": "07",
  KIWI: "07",
  "COOL SILVER": "10",
  "DK CHARCOAL HEATHER": "08",
  "CARBON STRIPE": "08",
  "CLASSIC BLUE STRIPE": "06",
  TITANIUM: "10",
  SPRUCE: "07",
  MALLARD: "07",
  GUNMETAL: "08",
  "COOL SILVER HEATHER": "10",
  "SCARLET RED": "01",
  ORANGE: "02",
  "CHARCOAL STRIPE": "08040000",
  "ZINC STRIPE": "08080000",
  "JEWEL GREEN": "07",
  CRIMSON: "01",
  CHIVE: "07",
  CYPRESS: "07",
  DUSK: "06",
  "EARTH PLAID": "07",
  "TITANIUM PLAID": "10",
  "ROYAL PLAID": "06",
  "GREY MELANGE": "08",
  "283 STONE": "05",
  STONE: "05",
  "CARBON MELANGE": "08",
  "NAVY MELANGE": "06",
  VIOLET: "09",
  "NAVY TWILL": "06",
  "GREY MIX": "08000001",
  "CHARCOAL MIX": "08",
  "DENIM HEATHER": "06",
  "MOSS HEATHER": "07",
  "DARK BLUE": "06",
  "DARK RED": "01",
  "STADIUM RED": "01",
  GOLD: "03",
  RASPBERRY: "09",
  BURGUNDY: "09",
  "FIRE ORANGE": "02"
};
