import { getSearchClient } from "services/typesense";
import { useDatabaseReader } from "database/useDatabaseReader";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import SearchSuggestion from "./SearchSuggestion";
import styled from "styled-components/macro";
import { COLORS, CONSTANTS } from "helpers/designConstants";

const SearchBar = ({ onSelectStyle }) => {
  const { articleSearchKey } = useDatabaseReader();

  const searchResults = async (searchTerm) => {
    const searchParameters = {
      q: searchTerm,
      num_typos: 2,
      min_len_1typo: 3,
      query_by: "itemNumber, name",
      include_fields: "itemNumber, name, color",
      sort_by: "itemNumber:asc",
      page: 1,
      per_page: 10,
      group_by: "itemNumber",
      group_limit: 1,
    };

    const searchClient = getSearchClient(articleSearchKey);
    const collection = ["local"].includes(
      process.env.REACT_APP_USER_ENVIRONMENT,
    )
      ? "staging.articles"
      : "production.articles";
    const results = await searchClient
      .collections(collection)
      .documents()
      .search(searchParameters);
    let parsedResults = results.grouped_hits.map(
      (group) => group.hits[0].document,
    );
    return parsedResults.map((result, index) => ({
      value: result.itemNumber,
      label: result.itemNumber,
      data: {
        itemNumber: result.itemNumber,
        itemName: result.name,
        color: result.color,
        sizes: result.sizes,
        isLastResult: parsedResults.length - 1 === index,
      },
    }));
  };

  return (
    <AsyncSelect
      loadOptions={searchResults}
      placeholder={"Search..."}
      // defaultValue={searchResults[0]}
      value={null}
      noOptionsMessage={() => null}
      onChange={onSelectStyle}
      components={{
        Control: ({ children, ...rest }) => (
          <components.Control {...rest}>
            <SearchIcon>
              <img src="/search.svg" alt="search" />
            </SearchIcon>
            {children}
          </components.Control>
        ),
        Option: SearchSuggestion,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          padding: "5px 0",
          boxShadow: state.isFocused
            ? `0 0 0 1px ${COLORS.stormtechOrange}`
            : "lightgray",
          borderColor: state.isFocused ? COLORS.stormtechOrange : "lightgray",
          borderRadius: CONSTANTS.borderRadius,
          "&:hover": {
            borderColor: COLORS.stormtechOrange,
          },
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          "z-index": 5,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? COLORS.secondary : "white",
        }),
      }}
    />
  );
};

const SearchIcon = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SearchBar;
