import { COLORS, FONTWEIGHT } from "helpers/designConstants";
import styled from "styled-components/macro";

const SearchSuggestion = (props) => {
  const {
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data
  } = props;

  return (
    <Wrapper
      isLastItem={data.data.isLastResult}
      ref={innerRef}
      css={getStyles("option", props)}
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected
        },
        className
      )}
      {...innerProps}
    >
      <DataWrapper>
        <ItemNumber>{data.data.itemNumber}</ItemNumber>
        <ItemName>{data.data.itemName}</ItemName>
      </DataWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
  border-bottom: ${({ isLastItem }) =>
    !isLastItem && `1px solid ${COLORS.tertiary}`};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DataWrapper = styled.div`
  color: ${COLORS.stormtechGrey};
`;
const ItemNumber = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
`;
const ItemName = styled.div``;

export default SearchSuggestion;
