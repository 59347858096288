import useNavigation from "helpers/useNavigation";
import { ChevronLeft, ChevronRight } from "lucide-react";

const LeftMenuNavSection = ({ location }) => {
  const [navigate] = useNavigation();

  return (
    <div className="flex w-full justify-start border-b border-st-orange bg-secondary">
      <button
        className="group flex items-center justify-end px-3 py-3 text-xl font-bold uppercase text-st-grey hover:text-st-orange"
        onClick={() => navigate(location.url)}
      >
        {location.direction === "left" && <ChevronLeft />}
        <span>{location.name}</span>
        {location.direction === "right" && <ChevronRight />}
      </button>
    </div>
  );
};

export default LeftMenuNavSection;
