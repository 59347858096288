import { useAuth } from "database/useAuth";
import useNavigation from "helpers/useNavigation";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { useLoading, ThreeDots } from "@agney/react-loading";

const LandingPage = ({ shouldRedirect = true }) => {
  const { authContextLoaded, user } = useAuth();
  const [navigate] = useNavigation();

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: {
        color: COLORS.stormtechGrey,
        maxWidth: "60vw",
        maxHeight: "40vh",
      },
      valueText: "Loading...",
    },
    indicator: <ThreeDots width="100px" />,
  });

  useEffect(() => {
    if (authContextLoaded && shouldRedirect) {
      if (user != null) {
        navigate("/private/search");
      } else {
        navigate("/login");
      }
    }
  }, [authContextLoaded, navigate, shouldRedirect, user]);

  return (
    <ContentWrapper>
      <Content>
        <LogoHeader>
          <Image src="/stormtech.svg" />
        </LogoHeader>
        <LoadingIconContainer
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
          {...containerProps}
        >
          {indicatorEl}
        </LoadingIconContainer>
      </Content>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 95%; */
  height: 100vh;
  width: 100vw;
`;

const LogoHeader = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
  font-size: ${FONTSIZE.xlarge};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const Image = styled.img`
  width: 300px;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    width: 45vw;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
`;

const LoadingIconContainer = styled.div`
  /* padding: 20px; */
  /* border-radius: 15px; */
  /* background-color: rgba(132, 132, 132, 0.8); */
`;

export default LandingPage;
