import { X } from "lucide-react";

const FacetSection = ({
  title,
  data,
  facetField,
  currentFilter,
  onSelectFacet,
  currentFacetSearch,
  onFacetSearchTermChanged,
}) => {
  return (
    <div className="grid h-full grid-rows-[auto__auto_1fr] overflow-y-hidden pt-0">
      <div className="border-b border-white text-lg font-bold uppercase text-white">
        {title}
      </div>
      <div className=" border-1 focus-within: mt-1 grid grid-cols-[auto_1fr_auto] items-center rounded-lg border border-secondary bg-white p-[6px] text-st-grey focus-within:border-st-orange focus-within:outline focus-within:outline-1 focus-within:outline-st-orange hover:border-st-orange">
        <div className="flex items-center justify-center pr-2">
          <img src="/search.svg" alt="search" />
        </div>
        <input
          type="text"
          className="m-0 border-0 p-[2px] text-sm font-normal outline-none"
          placeholder={`Search ${title}...`}
          value={
            currentFacetSearch != null &&
            currentFacetSearch.field === facetField
              ? currentFacetSearch.searchTerm
              : ""
          }
          onChange={(e) =>
            onFacetSearchTermChanged({
              field: facetField,
              searchTerm: e.target.value,
            })
          }
        />
        {currentFacetSearch != null &&
          currentFacetSearch.field === facetField &&
          currentFacetSearch.searchTerm.length > 0 && (
            <X
              className="cursor-pointer hover:text-st-orange"
              onClick={() => onFacetSearchTermChanged(null)}
            />
          )}
      </div>
      <ul className="h-full overflow-y-auto py-1">
        {data?.map((facet) => (
          <li
            key={facet.value}
            data-selected={currentFilter === facet.value}
            className="group my-1 flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1 text-white data-[selected=true]:bg-white data-[selected=true]:text-st-grey"
            onClick={() => {
              onSelectFacet(facet.value);
              onFacetSearchTermChanged(null);
            }}
          >
            <div className="grid grid-cols-[auto_1fr] items-center gap-1 overflow-hidden">
              {currentFilter === facet.value && (
                <X
                  size={14}
                  strokeWidth={4}
                  className="text-st-grey group-hover:text-st-orange"
                />
              )}
              <span className="overflow-hidden text-ellipsis whitespace-nowrap group-hover:font-semibold group-data-[selected=true]:font-semibold">
                {facet.value === "xxxx" ? "Unknown" : facet.value}
              </span>
            </div>
            <span className="rounded-md border border-st-orange px-2 text-xs text-st-orange">
              {facet.count}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FacetSection;
