import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS,
} from "helpers/designConstants";
import { Fragment, useCallback } from "react";
import { useLoading, ThreeDots } from "@agney/react-loading";
import { CopyMinus, CopyPlus } from "lucide-react";

const ColorCard = ({
  productData = {},
  selectedWarehouses = [],
  currency,
  showOversold = true,
  showItemNumber = false,
  liveInventory,
  isLoadingInventory,
  isSelectable = false,
  isSelected = false,
  onSelect,
}) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { color: `${COLORS.stormtechOrange}` },
    },
    indicator: <ThreeDots width="24" />,
  });

  const calculateCurrentInventoryTotal = useCallback(
    (warehouseCode, sizes, includeNegative) => {
      let total = 0;

      sizes.forEach((size) => {
        // console.log(liveInventory[size.sku]?.[warehouseCode].onHand);
        if (
          includeNegative &&
          liveInventory[size.sku]?.[warehouseCode].onHand != null
        ) {
          total = total + liveInventory[size.sku][warehouseCode].onHand;
        } else if (liveInventory[size.sku]?.[warehouseCode].onHand > 0) {
          total = total + liveInventory[size.sku][warehouseCode].onHand;
        }
      });

      return total;
    },
    [liveInventory],
  );

  const calculateSizeColumnATS = useCallback(
    (warehouseCode, size, incomingIndex, currentInventory) => {
      let total = parseInt(currentInventory) || 0;

      productData.incomingInventory[warehouseCode].forEach(
        (dateData, index) => {
          // console.log(dateData);
          if (index <= incomingIndex) {
            if (dateData.incomingBySize?.[size.name] != null) {
              // console.log(parseInt(dateData.incomingBySize[size.name]));
              total += parseInt(dateData.incomingBySize[size.name]);
            }
          }
        },
      );

      return total;
    },
    [productData.incomingInventory],
  );

  const calculateTotalColumnATS = useCallback(
    (warehouseCode, incomingIndex) => {
      let total = calculateCurrentInventoryTotal(
        warehouseCode,
        productData.sizes,
        true,
      );

      productData.incomingInventory[warehouseCode].forEach(
        (dateData, index) => {
          // console.log(dateData);
          if (index <= incomingIndex) {
            Object.entries(dateData.incomingBySize).forEach(([size, qty]) => {
              if (size !== "TOTAL") {
                if (qty > 0) {
                  total = total + qty;
                }
              }
            });
          }
        },
      );

      return total;
    },
    [
      calculateCurrentInventoryTotal,
      productData.incomingInventory,
      productData.sizes,
    ],
  );

  const calculateIncomingDateTotal = useCallback(
    (warehouseCode, incomingIndex) => {
      let total = 0;
      Object.entries(
        productData.incomingInventory[warehouseCode][incomingIndex]
          .incomingBySize,
      ).forEach(([size, qty]) => {
        if (size !== "TOTAL") {
          // if (qty > 0) {
          //   total = total + qty;
          // }

          if (qty != null) {
            const currentInventory =
              liveInventory[
                productData?.sizes?.find((sizeData) => sizeData.name === size)
                  ?.sku
              ]?.[warehouseCode]?.onHand;
            if (showOversold || currentInventory > 0) {
              total = total + qty;
            } else {
              if (currentInventory < 0) {
                total =
                  total +
                  calculateSizeColumnATS(
                    warehouseCode,
                    productData?.sizes?.find(
                      (sizeData) => sizeData.name === size,
                    ),
                    incomingIndex,
                    currentInventory,
                  );
              }
            }
          }
        }
      });
      return total;
    },
    [
      calculateSizeColumnATS,
      liveInventory,
      productData.incomingInventory,
      productData?.sizes,
      showOversold,
    ],
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <ProductDetailsWrapper>
          <ImageWrapper>
            <img
              src={productData.imageURL}
              alt="article"
              onError={(e) => {
                e.target.onError = null;
                e.currentTarget.src = "/st_placeholder.svg";
              }}
            />
          </ImageWrapper>
          <ProductInfoWrapper>
            {showItemNumber && (
              <ProductItemNumber>{productData.itemNumber}</ProductItemNumber>
            )}
            <div>{productData.name}</div>
            <ProductColorName>{productData.color}</ProductColorName>
            <ProductPrice>
              {productData.isClearance ||
              (currency === "$ CAD" && productData.price.CAD == null) ||
              (currency === "$ USD" && productData.price.USD == null) ||
              (currency === "£ GBP" && productData.price.GBP == null) ||
              (currency === "€ EUR" && productData.price.EURO == null) ? (
                <>
                  <a
                    href={productData.productURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See Website for Price
                  </a>
                </>
              ) : (
                `${currency.charAt(0)} ${
                  currency === "$ CAD"
                    ? productData.price.CAD
                    : currency === "$ USD"
                      ? productData.price.USD
                      : currency === "£ GBP"
                        ? productData.price.GBP
                        : currency === "€ EUR"
                          ? productData.price.EURO
                          : "??"
                } ${currency?.split(" ")[1]} (A)`
              )}
            </ProductPrice>
          </ProductInfoWrapper>
        </ProductDetailsWrapper>
        {isSelectable && (
          <ProductSelectionWrapper onClick={() => onSelect(productData.id)}>
            {isSelected ? (
              <CopyMinus className="text-st-orange hover:text-st-grey" />
            ) : (
              <CopyPlus className="text-st-grey hover:text-st-orange" />
            )}
          </ProductSelectionWrapper>
        )}
      </HeaderWrapper>
      <WarehousesWrapper>
        {selectedWarehouses.map((warehouse) => (
          <WarehouseContainer key={warehouse.letterCode}>
            <WarehouseTitle>{`${warehouse.name}`}</WarehouseTitle>
            <InventoryOuterContainer>
              <LabelBox>
                <CurrentInventoryDateTitle>
                  CURRENT<LiveLabel>LIVE DATA</LiveLabel>
                </CurrentInventoryDateTitle>
                {productData?.incomingInventory?.[warehouse.letterCode]
                  ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((dateData, index) => (
                    <Fragment
                      key={`${productData.color}-${dateData.date}-${index}-fragment`}
                    >
                      <InventoryDateTitle
                        key={`${productData.color}-${dateData.date}-${index}`}
                        row={index}
                      >
                        {new Date(dateData.date).toLocaleString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </InventoryDateTitle>
                      <InventoryTotalTitle
                        key={`${productData.color}-${dateData.date}-${index}-total`}
                        row={index}
                      >
                        ATS
                      </InventoryTotalTitle>
                    </Fragment>
                  ))}
              </LabelBox>
              <SizesContainer key={`${productData.color}`}>
                {productData?.sizes?.map(
                  (size) =>
                    !liveInventory[size.sku]?.[warehouse.letterCode]?.error && (
                      <SizeContainer key={`${productData.color}-${size.sku}`}>
                        <SizeTitle>{size.name}</SizeTitle>
                        <CurrentInventoryQty
                          negative={
                            liveInventory[size.sku]?.[warehouse.letterCode]
                              ?.onHand < 0 && showOversold
                          }
                        >
                          {/* <section {...containerProps}>{indicatorEl}</section> */}
                          {liveInventory[size.sku]?.[warehouse.letterCode]
                            ?.onHand != null ? (
                            showOversold ? (
                              liveInventory[size.sku]?.[warehouse.letterCode]
                                ?.onHand
                            ) : liveInventory[size.sku]?.[warehouse.letterCode]
                                ?.onHand > 0 ? (
                              liveInventory[size.sku]?.[warehouse.letterCode]
                                ?.onHand
                            ) : (
                              0
                            )
                          ) : isLoadingInventory ? (
                            <section {...containerProps}>{indicatorEl}</section>
                          ) : (
                            "?"
                          )}
                        </CurrentInventoryQty>
                        {productData?.incomingInventory?.[
                          warehouse.letterCode
                        ]?.map((dateData, index) => (
                          <Fragment
                            key={`${dateData.date}=${size.sku}-${index}-fragment`}
                          >
                            <InventoryQty
                              key={`${dateData.date}=${size.sku}-${index}`}
                              row={index}
                            >
                              {showOversold
                                ? dateData.incomingBySize[size.name] || "-"
                                : dateData.incomingBySize[size.name] >
                                    calculateSizeColumnATS(
                                      warehouse.letterCode,
                                      size,
                                      index,
                                      liveInventory[size.sku]?.[
                                        warehouse.letterCode
                                      ]?.onHand,
                                    )
                                  ? calculateSizeColumnATS(
                                      warehouse.letterCode,
                                      size,
                                      index,
                                      liveInventory[size.sku]?.[
                                        warehouse.letterCode
                                      ]?.onHand,
                                    )
                                  : dateData.incomingBySize[size.name] || "-"}
                            </InventoryQty>
                            <InventoryQtyTotal
                              key={`${dateData.date}=${size.sku}-${index}-total`}
                              negative={
                                calculateSizeColumnATS(
                                  warehouse.letterCode,
                                  size,
                                  index,
                                  liveInventory[size.sku]?.[
                                    warehouse.letterCode
                                  ]?.onHand,
                                ) < 0 && showOversold
                              }
                            >
                              {showOversold
                                ? calculateSizeColumnATS(
                                    warehouse.letterCode,
                                    size,
                                    index,
                                    liveInventory[size.sku]?.[
                                      warehouse.letterCode
                                    ]?.onHand,
                                  )
                                : calculateSizeColumnATS(
                                      warehouse.letterCode,
                                      size,
                                      index,
                                      liveInventory[size.sku]?.[
                                        warehouse.letterCode
                                      ]?.onHand,
                                    ) > 0
                                  ? calculateSizeColumnATS(
                                      warehouse.letterCode,
                                      size,
                                      index,
                                      liveInventory[size.sku]?.[
                                        warehouse.letterCode
                                      ]?.onHand,
                                    )
                                  : 0}
                            </InventoryQtyTotal>
                          </Fragment>
                        ))}
                      </SizeContainer>
                    ),
                )}
                <SizeContainer>
                  <SizeTitle>TOTAL</SizeTitle>
                  <CurrentInventoryQty
                    negative={
                      calculateCurrentInventoryTotal(
                        warehouse.letterCode,
                        productData.sizes,
                        true,
                      ) < 0 && showOversold
                    }
                  >
                    {showOversold
                      ? calculateCurrentInventoryTotal(
                          warehouse.letterCode,
                          productData.sizes,
                          true,
                        )
                      : calculateCurrentInventoryTotal(
                            warehouse.letterCode,
                            productData.sizes,
                            true,
                          ) > 0
                        ? calculateCurrentInventoryTotal(
                            warehouse.letterCode,
                            productData.sizes,
                            false,
                          )
                        : 0}
                  </CurrentInventoryQty>
                  {productData?.incomingInventory?.[warehouse.letterCode]?.map(
                    (dateData, index) => (
                      <Fragment
                        key={`${dateData.date}-TOTAL-${index}-fragment`}
                      >
                        <InventoryQty
                          key={`${dateData.date}-TOTAL-${index}`}
                          row={index}
                          negative={
                            calculateIncomingDateTotal(
                              warehouse.letterCode,
                              index,
                            ) < 0 && showOversold
                          }
                        >
                          {showOversold
                            ? calculateIncomingDateTotal(
                                warehouse.letterCode,
                                index,
                              )
                            : calculateIncomingDateTotal(
                                  warehouse.letterCode,
                                  index,
                                ) > 0
                              ? calculateIncomingDateTotal(
                                  warehouse.letterCode,
                                  index,
                                )
                              : 0}
                        </InventoryQty>
                        <InventoryQtyTotal
                          key={`${dateData.date}-TOTAL-${index}-total`}
                          negative={
                            calculateTotalColumnATS(
                              warehouse.letterCode,
                              index,
                            ) < 0 && showOversold
                          }
                        >
                          {showOversold
                            ? calculateTotalColumnATS(
                                warehouse.letterCode,
                                index,
                              )
                            : calculateTotalColumnATS(
                                  warehouse.letterCode,
                                  index,
                                ) > 0
                              ? calculateTotalColumnATS(
                                  warehouse.letterCode,
                                  index,
                                )
                              : 0}
                        </InventoryQtyTotal>
                      </Fragment>
                    ),
                  )}
                </SizeContainer>
              </SizesContainer>
            </InventoryOuterContainer>
          </WarehouseContainer>
        ))}
      </WarehousesWrapper>
    </Wrapper>
  );
};

export default ColorCard;

const Wrapper = styled.div`
  /* margin: 5px 5px; */
  /* padding: 10px; */
  width: 100%;
  max-width: 1100px;
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid ${COLORS.stormtechOrange};
  color: ${COLORS.text};
  display: flex;
  flex-direction: column;
  background: ${COLORS.primaryInverted};
  position: relative;
`;

const HeaderWrapper = styled.div`
  background: ${COLORS.primaryInverted};
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  /* padding: 10px 10px 0; */
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-between;
  /* border-bottom: 1px solid ${COLORS.secondary}; */
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  padding: 2px 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const ProductInfoWrapper = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ showItemNumber }) => (showItemNumber ? "0px" : "5px")};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
`;

const ProductItemNumber = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.small};
`;

const ProductColorName = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.medium};
`;

const ProductPrice = styled.div`
  padding-top: 5px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  a {
    color: ${COLORS.stormtechOrange};
    cursor: pointer;
  }
`;

const ProductSelectionWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const WarehousesWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  transition: height 1s;
  /* border: 1px solid ${COLORS.secondary}; */
`;

const WarehouseContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  :not(:last-child) {
    margin-bottom: 30px;
    /* border-bottom: 1px solid ${COLORS.tertiary}; */
  }
`;

const WarehouseTitle = styled.div`
  padding: 0px 5px;
  text-transform: uppercase;
  /* background: ${COLORS.secondary}; */
  color: ${COLORS.stormtechGrey};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  /* text-align: center; */
  /* border-top: 1px solid ${COLORS.stormtechGrey}; */
  border-bottom: 2px solid ${COLORS.stormtechGrey};
  /* box-shadow: 0px 3px 6px -4px ${COLORS.stormtechGrey}; */
`;

const InventoryOuterContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
const LabelBox = styled.div`
  white-space: nowrap;
  min-width: 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  /* padding: 10px 0 10px 0px; */
  padding: 5px 0 10px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  box-shadow: 6px 0 5px -6px ${COLORS.secondary};
  z-index: 1;
  overflow-x: auto;
  /* border-bottom: 1px solid ${COLORS.stormtechGrey}; */
`;

const SizesContainer = styled.div`
  /* padding: 10px 0 10px 0; */
  padding: 5px 0 10px;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
`;

const InventoryDateTitle = styled.div`
  width: 100%;
  /* height: 24px; */
  min-height: 24px;
  margin-top: 10px;
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  /* background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary}; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CurrentInventoryDateTitle = styled(InventoryDateTitle)`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.medium};
  margin-top: unset;
  height: 26px;
  display: flex;
  align-items: center;
  position: relative;
`;

const LiveLabel = styled.div`
  position: absolute;
  top: -9px;
  right: 5px;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  animation: color-change ease-in-out 2s infinite;

  @keyframes color-change {
    0% {
      color: ${COLORS.stormtechOrange};
    }
    50% {
      color: ${COLORS.secondary};
    }
    100% {
      color: ${COLORS.stormtechOrange};
    }
  }
`;

const InventoryTotalTitle = styled.div`
  width: 100%;
  /* height: 26px; */
  min-height: 26px;
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  /* display: flex;
  align-items: center;
  justify-content: flex-end; */
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.tertiary};
`;

const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 82px;

  /* margin: 5px; */
  flex-shrink: 0;
`;

const SizeTitle = styled.div`
  padding-right: 15px;
  width: 100%;
  text-align: right;
  /* margin-bottom: 5px; */
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
`;

const InventoryQty = styled.div`
  /* height: 24px; */
  min-height: 24px;
  width: 100%;
  margin-top: 10px;
  padding: 3px;
  padding-right: 15px;
  text-align: right;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.medium};
  font-size: ${FONTSIZE.small};
  /* background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary}; */
`;

const CurrentInventoryQty = styled(InventoryQty)`
  height: 27px;
  /* min-height: 26px; */
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${({ negative }) => (negative ? COLORS.alertRed : COLORS.text)};
  margin-top: unset;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InventoryQtyTotal = styled.div`
  /* height: 26px; */
  min-hieght: 26px;
  width: 100%;
  /* margin-top: 3px; */
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  padding-right: 15px;
  text-align: right;
  /* color: ${COLORS.text}; */
  color: ${({ negative }) => (negative ? COLORS.alertRed : COLORS.text)};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.tertiary};
`;
