import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// For emulators this connects to local emulator
// if (
//   window.location.hostname.includes("localhost") ||
//   window.location.hostname.includes("192.168")
// ) {
//   firebaseConfig.databaseURL = `http://${window.location.hostname}:9001?ns=stormtech-inventory`;
// }

const setupEmulators = () => {
  if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("192.168") ||
    window.location.hostname.includes("bs-local") ||
    window.location.hostname.includes("scotts-macbook-pro-2023.local")
  ) {
    //Auth Emulator
    connectAuthEmulator(getAuth(), `http://${window.location.hostname}:9095`);

    //Functions Emulator
    connectFunctionsEmulator(getFunctions(), window.location.hostname, 5003);

    //Firestore Emulator
    const db = getFirestore();
    connectFirestoreEmulator(db, window.location.hostname, 8081);

    //Storage Emulator
    const storage = getStorage();
    connectStorageEmulator(storage, window.location.hostname, 9199);
  }
};

const inititializeFirebase = () => {
  let firebaseApp;
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
    setupEmulators();
  } else {
    firebaseApp = getApp();
  }
  return firebaseApp;
};

export default inititializeFirebase;
