import Resizer from "react-image-file-resizer";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const cropImage = async (file, canvas, imageID, onImageDone) => {
  const image = new Image();
  // var canvas = document.getElementById("canvas");
  const context = canvas.getContext("2d");
  let data = {};

  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      image.src = reader.result;
    },
    false,
  );
  if (file) {
    reader.readAsDataURL(file);
  }

  image.onload = function () {
    canvas.width = this.width;
    canvas.height = this.height;

    context.drawImage(this, 0, 0, image.width, image.height);
    cropImage();
  };

  function cropImage() {
    data = context.getImageData(0, 0, image.width, image.height).data;

    var top = scanY(true);
    var bottom = scanY(false);
    var left = scanX(true);
    var right = scanX(false);

    var new_width = right - left;
    var new_height = bottom - top;

    canvas.width = new_width;
    canvas.height = new_height;

    context.drawImage(
      image,
      left,
      top,
      new_width,
      new_height,
      0,
      0,
      new_width,
      new_height,
    );

    canvas.toBlob(async (blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      const resizedFile = await resizeFile(blob, "JPG");
      let logoURL;
      const storage = getStorage();
      const storageRef = ref(storage, `logos/${imageID}.jpg`);
      try {
        const result = await uploadBytes(storageRef, resizedFile);
        const downloadURL = await getDownloadURL(result.ref);
        logoURL = downloadURL;
      } catch (error) {
        console.log(error);
      }

      // logoURL = logoURL?.substr(0, logoURL.lastIndexOf("&token"));
      logoURL = logoURL?.replace("localhost", "scotts-macbook-pro-2023.local");

      onImageDone(logoURL, blob);
    });
  }

  async function resizeFile(file, type) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        200,
        type,
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
      );
    });
  }

  function scanX(left) {
    var offset = left ? 1 : -1;

    for (
      var x = left ? 0 : image.width - 1;
      left ? x < image.width : x > -1;
      x += offset
    ) {
      for (var y = 0; y < image.height; y++) {
        if (!isColor(getRGBa(x, y))) {
          return x;
        }
      }
    }

    return null;
  }

  function scanY(top) {
    var offset = top ? 1 : -1;

    for (
      var y = top ? 0 : image.height - 1;
      top ? y < image.height : y > -1;
      y += offset
    ) {
      for (var x = 0; x < image.width; x++) {
        if (!isColor(getRGBa(x, y))) {
          return y;
        }
      }
    }

    return null;
  }

  function isColor(rgba) {
    return (
      (rgba.red > 240 && rgba.green > 240 && rgba.blue > 240) ||
      rgba.alpha === 0
    );
  }

  function getRGBa(x, y) {
    return {
      red: data[(image.width * y + x) * 4],
      green: data[(image.width * y + x) * 4 + 1],
      blue: data[(image.width * y + x) * 4 + 2],
      alpha: data[(image.width * y + x) * 4 + 3],
    };
  }
};
