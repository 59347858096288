import { useDatabaseReader } from "database/useDatabaseReader";
import { Check, X } from "lucide-react";
import { onUpdateProjectWarehouses } from "database/projectsWriter";
import { toast } from "react-toastify";

export default function WarehousePicker({ projID, selectedWarehouses }) {
  const { warehouses } = useDatabaseReader();

  const onSelectWarehouse = async (warehouse) => {
    let currentWarehouses = [...selectedWarehouses];
    if (selectedWarehouses.find((w) => w.letterCode === warehouse.letterCode)) {
      currentWarehouses = selectedWarehouses.filter(
        (w) => w.letterCode !== warehouse.letterCode,
      );
    } else {
      currentWarehouses.push(warehouse);
    }

    currentWarehouses.sort((a, b) => {
      return (
        warehouses.findIndex((warehouse) => warehouse.name === a.name) -
        warehouses.findIndex((warehouse) => warehouse.name === b.name)
      );
    });

    if (currentWarehouses.length === 0) {
      toast.error("You must select at least one warehouse");
    } else {
      await onUpdateProjectWarehouses(projID, currentWarehouses);
    }
  };

  return (
    <div className="grid grid-rows-[auto_auto]">
      <div className="border-b border-white text-lg font-bold uppercase text-white">
        Warehouses
      </div>
      <ul className="h-full overflow-y-auto py-1">
        {warehouses?.map((warehouse) => (
          <li
            key={warehouse.letterCode}
            data-selected={
              selectedWarehouses?.find(
                (w) => w.letterCode === warehouse.letterCode,
              )
                ? true
                : false
            }
            className="group my-1 flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1 text-white data-[selected=true]:bg-white data-[selected=true]:text-st-grey"
            onClick={() => {
              onSelectWarehouse(warehouse);
            }}
          >
            <div className="grid grid-cols-[1fr_auto] items-center gap-1 overflow-hidden">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap uppercase group-hover:font-semibold group-data-[selected=true]:font-semibold">
                {warehouse.name}
              </span>
            </div>
            <div className="flex items-center group-data-[selected=false]:hidden">
              <Check size={20} className="group-hover:hidden" />
              <X
                size={20}
                className="hidden text-st-orange group-hover:inline"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
