import React, { useState } from "react";
import styled from "styled-components/macro";
import Modal from "components/Modal";
import { Button } from "components/standardElements";
import { COLORS } from "helpers/designConstants";
import { useLoading, ThreeDots } from "@agney/react-loading";

const ConfirmModal = ({
  show,
  isDestructive,
  onClose,
  onConfirm,
  delayConfirm = false,
  title,
  message,
  buttonTitle,
  ...props
}) => {
  const [confirming, setConfirming] = useState(false);

  const { containerProps, indicatorEl } = useLoading({
    loading: confirming,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { color: "rgb(204, 0, 0)" },
    },
    indicator: <ThreeDots width="40px" />,
  });

  const userConfirm = () => {
    if (delayConfirm) {
      setConfirming(true);
      setTimeout(() => {
        onConfirm();
      }, 500);
    } else {
      onConfirm();
    }
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={onClose}
      closeButtonHidden
      disableCloseOnOutsideClick
      {...props}
    >
      <Container>
        <MessageWrapper>{message}</MessageWrapper>
        <ButtonWrapper>
          <StyledButton
            disabled={confirming}
            cancel
            onClick={onClose}
            type="button"
          >
            {"Cancel"}
          </StyledButton>
          <StyledButton
            disabled={confirming}
            destructive={isDestructive}
            onClick={userConfirm}
            type="button"
          >
            {confirming ? (
              <div
                // style={{ backgroundColor: "rgb(255,255,255)" }}
                {...containerProps}
              >
                {indicatorEl}
              </div>
            ) : (
              buttonTitle
            )}
          </StyledButton>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 30px;
  max-width: 700px;
`;

const MessageWrapper = styled.div`
  text-align: center;
  color: ${COLORS.text};
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 5px 0 5px;
  flex: 1 1 0px;
  &:hover {
    background: ${({ disabled }) => (disabled ? "white" : COLORS.alertRed)};
  }
`;

export default ConfirmModal;
