import { useAuth } from "database/useAuth";
import {
  COLORS,
  CONSTANTS,
  FONTWEIGHT,
  FONTSIZE,
} from "helpers/designConstants";
import React, { useState } from "react";
import styled from "styled-components";
import EditLoggedInUser from "components/EditLoggedInUser";

const UserSettingsSection = ({ onSelectedEditUser }) => {
  const { user, logout } = useAuth();
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  return (
    <Wrapper>
      <Title>{user.displayName}</Title>
      <SettingsList>
        <SettingsListItem onClick={() => setShowEditUserModal(true)}>
          Edit User
        </SettingsListItem>
        <SettingsListItem onClick={logout}>Logout</SettingsListItem>
      </SettingsList>
      <EditLoggedInUser
        title="EDIT USER"
        user={user}
        show={showEditUserModal}
        onClose={() => setShowEditUserModal(!showEditUserModal)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0px;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid white;
  font-size: ${FONTSIZE.medium};
`;

const SettingsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 5px;
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.medium};
  color: white;
`;

const SettingsListItem = styled.li`
  padding: 10px 10px;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${({ selected }) => (selected ? "white" : "inherit")};
  color: ${({ selected }) => (selected ? COLORS.stormtechGrey : "white")};
  font-weight: ${({ selected }) => selected && FONTWEIGHT.heavy};
  cursor: pointer;
  user-select: none;

  :hover {
    font-weight: ${({ selected }) => !selected && FONTWEIGHT.heavy};
  }
`;

export default UserSettingsSection;
