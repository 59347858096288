import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";

export const createProject = async (
  projectName,
  distName,
  distRep,
  currency,
  warehouses,
  collaboratorIDs,
) => {
  try {
    const newProject = {
      name: projectName,
      users: collaboratorIDs,
      createdAt: new Date(),
      modifiedAt: new Date(),
      currency: currency,
      warehouses: warehouses,
      distributor: distName != null && distName.length > 0 ? distName : "xxxx",
      rep: distRep != null && distRep.length > 0 ? distRep : "xxxx",
    };

    const db = getFirestore();
    const docRef = await addDoc(collection(db, "projects"), newProject);
    return docRef.id;
  } catch (error) {
    throw error;
  }
};

export const onUpdateProjectSearchableData = async (
  projID,
  projectName,
  distName,
  distRep,
  collaboratorIDs,
) => {
  try {
    const db = getFirestore();
    await updateDoc(doc(db, "projects", projID), {
      name: projectName,
      modifiedAt: new Date(),
      distributor: distName != null && distName.length > 0 ? distName : "xxxx",
      rep: distRep != null && distRep.length > 0 ? distRep : "xxxx",
      users: collaboratorIDs,
    });
  } catch (error) {
    console.log("Error saving project details", error);
  }
};

export const onUpdateProjectWarehouses = async (projID, selectedWarehouses) => {
  try {
    const db = getFirestore();
    await updateDoc(doc(db, "projects", projID), {
      warehouses: selectedWarehouses,
    });
  } catch (error) {
    console.log("Error saving project settings", error);
  }
};

export const onUpdateProjectCurrency = async (projID, currency) => {
  try {
    const db = getFirestore();
    await updateDoc(doc(db, "projects", projID), {
      currency: currency,
    });
  } catch (error) {
    console.log("Error saving project settings", error);
  }
};

export const onDeleteProject = async (projID) => {
  try {
    const db = getFirestore();
    await deleteDoc(doc(db, "projects", projID));
  } catch (error) {
    console.log("Error saving project settings", error);
  }
};
