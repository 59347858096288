import { useRef } from "react";
import { COLORS, CONSTANTS, FONTWEIGHT } from "helpers/designConstants";
import styled from "styled-components/macro";
import SearchBar from "./searchBar/SearchBar";
import ColorCard from "components/ColorCard";
import useSelectedArticle from "components/searchWindow/useSelectedArticle";
import { useDatabaseReader } from "database/useDatabaseReader";

const SearchWindow = ({
  selectedWarehouses,
  isSelectable,
  selectedItems = [],
  onSelectItem,
}) => {
  const {
    selectedArticleData,
    currentInventory,
    currentInventoryLoading,
    selectedIndex,
    setSelectedIndex,
    onSelectStyle,
  } = useSelectedArticle();
  const { preferredCurrency } = useDatabaseReader();

  const scrollRef = useRef();

  return (
    <Wrapper>
      <Header>
        <SearchWrapper>
          <SearchBar onSelectStyle={onSelectStyle} />
        </SearchWrapper>
        <ResultsHeader>
          {selectedArticleData?.length > 0 && (
            <ItemNumberSelector>
              {selectedArticleData?.map((article, index) => (
                <ItemNumberSelectorOption
                  key={article.itemNumber}
                  selected={index === selectedIndex}
                  onClick={() => {
                    setSelectedIndex(index);
                    // scrollRef.current.scrollTo(0, 0);
                  }}
                >
                  {article.itemNumber}
                </ItemNumberSelectorOption>
              ))}
            </ItemNumberSelector>
          )}
        </ResultsHeader>
      </Header>
      <ResultsWrapper>
        <ScrollResults ref={scrollRef}>
          {selectedArticleData.length === 0 ? (
            <LogoContainer>
              <img src="/stormtech.svg" alt="" />
            </LogoContainer>
          ) : (
            selectedArticleData[selectedIndex]?.colors.map((colorData) => (
              <ColorCard
                key={colorData.id}
                productData={colorData}
                selectedWarehouses={selectedWarehouses}
                currency={preferredCurrency}
                liveInventory={currentInventory}
                isLoadingInventory={currentInventoryLoading}
                isSelectable={isSelectable}
                isSelected={selectedItems.includes(colorData.id)}
                onSelect={onSelectItem}
              />
            ))
          )}
        </ScrollResults>
      </ResultsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${COLORS.tertiary};
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
  overflow-y: hidden;
`;

const Header = styled.div`
  width: 100%;
  padding: 5px;
  /* background-color: gray; */
  /* border-bottom: 1px solid ${COLORS.stormtechGrey}; */

  box-shadow: 0px 4px 6px -4px ${COLORS.secondary};
  z-index: 3;

  @media (max-height: 470px) {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
  }

  @media (min-width: 1024px) {
    padding: 10px;
  }
`;

const SearchWrapper = styled.div`
  @media (min-width: 1024px) {
    max-width: 500px;
  }
`;

const ResultsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  overflow-y: hidden;
`;

const ResultsHeader = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0px 0px;
  /* border-bottom: 1px solid black; */
  /* box-shadow: 0px 4px 6px -4px ${COLORS.stormtechGrey};
  z-index: 3; */
  display: flex;

  @media (max-height: 470px) {
    padding-top: 0px;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    opacity: 100%;
    max-width: 30%;
    padding-bottom: 85px;

    @media screen and (max-width: 500px) {
      max-width: 50%;
    }
  }
`;

const ItemNumberSelector = styled.div`
  background: ${COLORS.secondary};
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  /* padding: 10px; */
  display: flex;
  gap: 1px;
  align-items: center;
  user-select: none;
`;

const ItemNumberSelectorOption = styled.div`
  padding: 10px 20px;
  white-space: nowrap;
  background-color: ${({ selected }) =>
    selected ? COLORS.alertOrange : COLORS.primaryInverted};
  color: ${({ selected }) =>
    selected ? COLORS.primaryInverted : COLORS.stormtechGrey};
  font-weight: ${({ selected }) =>
    selected ? FONTWEIGHT.heavy : FONTWEIGHT.medium};
  cursor: pointer;

  @media (min-width: 320px) and (max-width: 370px) {
    font-size: 10px;
  }

  @media (min-width: 370px) and (max-width: 500px) {
    font-size: 14px;
  }

  :hover {
    background-color: ${({ selected }) =>
      selected ? COLORS.stormtechOrange : COLORS.text};
    color: ${COLORS.primaryInverted};
  }

  :first-child {
    border-radius: 7px 0 0 7px;
  }

  :last-child {
    border-radius: 0 7px 7px 0;
  }

  :only-child {
    border-radius: 7px;
  }
`;

const ScrollResults = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 5px 5px 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
  background-color: ${COLORS.secondary};
`;

export default SearchWindow;
