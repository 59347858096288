import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";
import { useLoading, ThreeDots } from "@agney/react-loading";

const Loading = ({ isLoading }) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: isLoading,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { color: "white", maxWidth: "60vw", maxHeight: "40vh" },
      valueText: "Loading...",
    },
    indicator: <ThreeDots width="100px" />,
  });

  return ReactDOM.createPortal(
    <Fragment>
      {isLoading ? (
        <LoadingBackground>
          <LoadingIconContainer
            style={{ backgroundColor: "rgba(0,0,0,0)" }}
            {...containerProps}
          >
            {indicatorEl}
          </LoadingIconContainer>

          {/* <OuterOverlayContainer>
            <InnerOverlayContainer>
              <img src='/stormtech.svg' alt='stormtechImage' />
            </InnerOverlayContainer>
          </OuterOverlayContainer> */}
        </LoadingBackground>
      ) : null}
    </Fragment>,
    document.getElementById("root"),
  );
};

export default Loading;

const LoadingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIconContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(132, 132, 132, 0.8);
`;
