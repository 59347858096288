import { useCallback } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

const useNavigation = () => {
  const nav = useNavigate();

  const navigate = useCallback(
    (pathname, newSearchParams) => {
      // let previousLocation = location.state?.previousLocation
      //   ? [...location.state?.previousLocation]
      //   : [];
      // previousLocation.push(`${location.pathname}${location.search}`);

      nav(
        {
          pathname: pathname,
          search: `?${createSearchParams(newSearchParams)}`,
        },
        // { state: { previousLocation: previousLocation } }
      );
    },
    [nav],
  );
  return [navigate];
};

export default useNavigation;
