import LeftMenuNavSection from "components/LeftMenuNavSection";
import WarehousePicker from "./WarehousePicker";
import CurrencyPicker from "./CurrencyPicker";
import ProjectDetailsSection from "./ProjectDetailsSection";
import ProjectLogoSection from "./logoSection/ProjectLogoSection";

const LeftMenu = ({ onMenuClose, project, onSelectEditDetails }) => {
  return (
    <div className="grid h-full w-full grid-rows-[auto_1fr] overflow-y-hidden">
      <div>
        <LeftMenuNavSection
          location={{
            name: "Projects",
            url: "/private/projects",
            direction: "left",
          }}
        />
      </div>
      <div className="flex h-full flex-col gap-5 overflow-y-scroll pt-5">
        <div className="px-2">
          <ProjectLogoSection project={project} />
        </div>
        <div className="px-2">
          <ProjectDetailsSection
            project={project}
            onSelectEditDetails={onSelectEditDetails}
          />
        </div>
        <div className="px-2">
          <WarehousePicker
            projID={project?.id}
            selectedWarehouses={project?.warehouses}
          />
        </div>
        <div className="px-2">
          <CurrencyPicker
            projID={project?.id}
            selectedCurrency={project?.currency}
          />
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
