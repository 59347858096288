import WarehousesSection from "./WarehousesSection";
import UserSettingsSection from "./UserSettingsSection";
import CurrencyPicker from "./CurrencySection";
import LeftMenuNavSection from "components/LeftMenuNavSection";
import { useAuth } from "database/useAuth";
import clsx from "clsx";

const LeftMenu = ({ selectedWarehouse, onSelectedWarehouse, onMenuClose }) => {
  const { user } = useAuth();

  return (
    <div
      className={clsx(
        "grid h-full overflow-y-hidden",
        user?.userAccess == null ||
          (user?.userAccess?.length === 0 && user?.userAccess[0] === "search")
          ? "grid-rows-[1fr] pt-5"
          : "grid-rows-[auto_1fr]",
      )}
    >
      {user?.userAccess?.includes("projects") && (
        // <Content>
        <LeftMenuNavSection
          location={{
            name: "Projects",
            url: "/private/projects",
            direction: "right",
          }}
        />
        // </Content>
      )}
      <div className="flex flex-col justify-between overflow-y-auto pt-5">
        <div className="flex flex-col gap-5">
          <div className="px-2">
            <WarehousesSection
              selectedWarehouse={selectedWarehouse}
              onSelectedWarehouse={onSelectedWarehouse}
            />
          </div>
          <div className="px-2">
            <CurrencyPicker onCloseModal={onMenuClose} />
          </div>
        </div>
        <div className="px-2">
          <UserSettingsSection />
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
