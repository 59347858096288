import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import { cropImage } from "./logoProcessing";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const ProjectLogoSection = ({ project }) => {
  const canvasRef = useRef();

  const [imageBlob, setImageBlob] = useState();

  const onUploadFile = async (file) => {
    cropImage(file, canvasRef.current, project.id, async (url, blob) => {
      try {
        const db = getFirestore();
        await updateDoc(doc(db, "projects", project.id), {
          logoURL: url,
        });
      } catch (error) {
        console.log("Error adding item to cart", error);
      }
      setImageBlob(blob);
    });
  };

  return (
    <Wrapper>
      <Title>Logo</Title>
      <div className="h-[120px] w-full overflow-x-hidden p-2 hover:cursor-pointer">
        <FileUploader
          handleChange={onUploadFile}
          name="courseLogo"
          types={["JPG", "JPEG", "PNG", "SVG"]}
          onTypeError={(err) => console.log(err)}
          dropMessageStyle={{
            backgroundColor: COLORS.tertiary,
            opacity: 0.95,
            color: COLORS.stormtechGrey,
            fontWeight: FONTWEIGHT.heavy,
            textTransform: "uppercase",
            border: `2px dashed ${COLORS.stormtechOrange}`,
          }}
          hoverTitle={"Drop here"}
        >
          <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-md bg-white px-9 py-4">
            <img
              className="max-h-full max-w-full object-contain"
              src={
                (imageBlob && URL.createObjectURL(imageBlob)) ||
                project?.logoURL ||
                "/stormtech_icon_placeholder.svg"
              }
              alt="logo"
            />
          </div>
        </FileUploader>
      </div>
      <div className="w-full text-center text-sm uppercase text-white">
        Drop an image above
      </div>
      <canvas ref={canvasRef} id="canvas" className="hidden" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0px;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid white;
  font-size: ${FONTSIZE.medium};
`;

export default ProjectLogoSection;
