import React from "react";
import { useDatabaseReader } from "database/useDatabaseReader";
import { onUpdateProjectCurrency } from "database/projectsWriter";

export default function CurrencyPicker({ projID, selectedCurrency }) {
  const { currencyOptions } = useDatabaseReader();

  return (
    <div className="grid grid-rows-[auto_auto]">
      <div className="border-b border-white text-lg font-bold uppercase text-white">
        Currency
      </div>
      <ul className="h-full overflow-y-auto py-1">
        {currencyOptions?.map((currency) => (
          <li
            key={currency}
            data-selected={selectedCurrency === currency}
            className="group my-1 flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1 text-white data-[selected=true]:bg-white data-[selected=true]:text-st-grey"
            onClick={() => {
              onUpdateProjectCurrency(projID, currency);
            }}
          >
            <div className="grid grid-cols-[1fr_auto] items-center gap-1 overflow-hidden">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap uppercase group-hover:font-semibold group-data-[selected=true]:font-semibold">
                {currency}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
