import { FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import React from "react";
import styled from "styled-components";

const ProjectDetailsSection = ({ project, onSelectEditDetails }) => {
  return (
    <Wrapper>
      <Title>
        <div>DETAILS</div>
        <img
          className="h-[25px] cursor-pointer"
          src="/edit_icon.svg"
          alt="edit"
          onClick={onSelectEditDetails}
        />
      </Title>
      <div className="flex w-full flex-col gap-1 pb-4 pt-2">
        <div className="px-2">
          <div className="text-xs font-extralight uppercase text-white">
            Name:
          </div>
          <div className="pl-2 text-base uppercase text-white">
            {project?.name}
          </div>
        </div>
        {project?.distributor && (
          <div className="px-2">
            <div className="text-xs font-extralight uppercase text-white">
              Distributor:
            </div>
            <div className="pl-2 text-base uppercase text-white">
              {project?.distributor !== "xxxx"
                ? project?.distributor
                : "Unknown"}
            </div>
          </div>
        )}
        {project?.rep && (
          <div className="px-2">
            <div className="text-xs font-extralight uppercase text-white">
              Rep:
            </div>
            <div className="pl-2 text-base uppercase text-white">
              {project?.rep !== "xxxx" ? project?.rep : "Unknown"}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0px;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid white;
  font-size: ${FONTSIZE.medium};
  display: flex;
  justify-content: space-between;
`;

export default ProjectDetailsSection;
