import Typesense from "typesense";

export const getSearchClient = (apiKey) => {
  return new Typesense.Client({
    nodes: [
      {
        host:
          window.location.hostname.includes("localhost") ||
          window.location.hostname.includes("192.168") ||
          window.location.hostname.includes("bs-local") ||
          window.location.hostname.includes("scotts-macbook-pro-2023.local")
            ? window.location.hostname
            : process.env.REACT_APP_TYPESENSE_HOST,
        port: process.env.REACT_APP_TYPESENSE_PORT,
        protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
      },
    ],
    apiKey: apiKey,
    connectionTimeoutSeconds: 5,
  });
};
