import {
  COLORS,
  CONSTANTS,
  FONTWEIGHT,
  FONTSIZE,
} from "helpers/designConstants";
import React from "react";
import styled from "styled-components";
import { useDatabaseReader } from "database/useDatabaseReader";

const CurrencySection = ({ onCloseModal }) => {
  const { currencyOptions, preferredCurrency, changePreferredCurrency } =
    useDatabaseReader();

  const onSelectCurrency = (currency) => {
    changePreferredCurrency(currency);
    onCloseModal();
  };

  return (
    <Wrapper>
      <Title>Currency</Title>
      <List>
        {currencyOptions.map((currency) => (
          <ListItem
            key={currency}
            selected={preferredCurrency === currency}
            onClick={() => onSelectCurrency(currency)}
          >
            {currency}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0px;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid white;
  font-size: ${FONTSIZE.medium};
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 5px;
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.medium};
  color: white;
`;

const ListItem = styled.li`
  padding: 10px 10px;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${({ selected }) => (selected ? "white" : "inherit")};
  color: ${({ selected }) => (selected ? COLORS.stormtechGrey : "white")};
  font-weight: ${({ selected }) => selected && FONTWEIGHT.heavy};
  cursor: pointer;
  user-select: none;

  :hover {
    font-weight: ${({ selected }) => !selected && FONTWEIGHT.heavy};
  }
`;

export default CurrencySection;
