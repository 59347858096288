import React, { useState, useRef, useEffect } from "react";
import Modal from "components//Modal";
import LabelledTextInput from "components/LabelledTextInput";
import { useForm } from "react-hook-form";
import { Button } from "components/standardElements";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import { useAuth } from "database/useAuth";

//Extends Modal Component
const EditLoggedInUser = ({ show, onClose, title, user, ...props }) => {
  //HOOKS
  const {
    register: registerDetailsForm,
    handleSubmit: handleChangeDetailsSubmit,
    formState: { errors: userDetailsErrors },
    getValues: getUserDetailsValues,
    reset: restUserDetailsForm,
    watch: watchUserDetails
  } = useForm();
  const {
    register: registerChangePasswordForm,
    handleSubmit: handlePasswordChangeSubmit,
    getValues: getPasswordChangeValues,
    formState: { errors: newPasswordErrors },
    reset: resetPasswordForm,
    watch: watchPasswordFields
  } = useForm();

  const { changePassword, updateUserDetails, reauthenticateUser } = useAuth();

  const [showLoading, setShowLoading] = useState(false);
  const [showEnterCurrentPassword, setShowEnterCurrentPassword] =
    useState(false);
  const [reauthReason, setReauthReason] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [emailFieldValue, setEmailFieldValue] = useState();
  const [newPasswordFieldValue, setNewPasswordFieldValue] = useState();
  const [verifyPasswordFieldValue, setVerifyPasswordFieldValue] = useState();

  const currentPasswordRef = useRef();

  useEffect(() => {
    const subscriptionToUserDetails = watchUserDetails((value) => {
      setNameFieldValue(value.userName);
      setEmailFieldValue(value.email);
    });
    const subscriptionToPasswordFields = watchPasswordFields((value) => {
      setNewPasswordFieldValue(value.newPassword);
      setVerifyPasswordFieldValue(value.verifyPassword);
    });
    return () => {
      subscriptionToUserDetails.unsubscribe();
      subscriptionToPasswordFields.unsubscribe();
    };
  }, [watchUserDetails, watchPasswordFields]);

  const onUpdateUser = async (data) => {
    setShowLoading(true);
    try {
      await updateUserDetails(data.userName, data.email);
      setShowLoading(false);
      toast.success("User has been updated");
      onClose();
    } catch (error) {
      setShowLoading(false);

      if (error.code === "auth/requires-recent-login") {
        setReauthReason("userDetails");
        setShowEnterCurrentPassword(true);
      }

      let errorMessage = "";
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "Email already exists";
      } else {
        errorMessage = "There was an error";
      }
      toast.error(errorMessage);
      console.log(error.code);
    }
  };

  const onChangePassword = async (data) => {
    setShowLoading(true);
    try {
      await changePassword(data.newPassword);
      setShowLoading(false);
      toast.success("Password has been updated");
      onClose();
      restUserDetailsForm();
      resetPasswordForm();
    } catch (error) {
      setShowLoading(false);
      if (error.code === "auth/requires-recent-login") {
        setReauthReason("changePassword");
        setShowEnterCurrentPassword(true);
      }
      console.log(error);
    }
  };

  const reauthenticate = async () => {
    setShowLoading(true);
    await reauthenticateUser(currentPasswordRef.current.value);
    setShowLoading(false);
    setShowEnterCurrentPassword(false);
    if (reauthReason === "userDetails") {
      onUpdateUser(getUserDetailsValues());
    } else {
      onChangePassword(getPasswordChangeValues());
    }
  };

  const closeModal = () => {
    restUserDetailsForm();
    resetPasswordForm();
    onClose();
  };

  return (
    <Modal
      disableCloseOnOutsideClick
      title={title}
      show={show}
      onClose={closeModal}
      {...props}
    >
      <Container>
        <StyledForm onSubmit={handleChangeDetailsSubmit(onUpdateUser)}>
          <InnerFormContainer>
            <InnerFormTitle>User Details</InnerFormTitle>
            <InputAndErrorWrapper>
              <StyledTextInput
                labelText='Name'
                type='text'
                id='userName'
                name='userName'
                placeholder='Name'
                defaultValue={user?.displayName}
                {...registerDetailsForm("userName", {
                  required: "Name is Required"
                })}
              />
              {userDetailsErrors.userName && (
                <StyledInputErrorMessage>
                  {userDetailsErrors.userName.message}
                </StyledInputErrorMessage>
              )}
            </InputAndErrorWrapper>
            <InputAndErrorWrapper>
              <StyledTextInput
                labelText='Email Address'
                type='email'
                id='email'
                name='email'
                placeholder='Email Address'
                defaultValue={user?.email}
                {...registerDetailsForm("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email Address is Invalid"
                  }
                })}
              />
              {userDetailsErrors.email && (
                <StyledInputErrorMessage>
                  {userDetailsErrors.email.message}
                </StyledInputErrorMessage>
              )}
            </InputAndErrorWrapper>
            <StyledButton
              primary
              disabled={
                (nameFieldValue === user?.displayName &&
                  emailFieldValue === user?.email) ||
                nameFieldValue == null ||
                emailFieldValue == null ||
                nameFieldValue === "" ||
                emailFieldValue === ""
              }
            >
              Update
            </StyledButton>
          </InnerFormContainer>
        </StyledForm>
        <Divider />
        <StyledForm onSubmit={handlePasswordChangeSubmit(onChangePassword)}>
          <InnerFormContainer>
            <InnerFormTitle>Change Password</InnerFormTitle>
            <InputAndErrorWrapper>
              <StyledTextInput
                labelText='New Password'
                type='password'
                id='newPassword'
                name='newPassword'
                placeholder='New Password'
                {...registerChangePasswordForm("newPassword")}
                {...registerChangePasswordForm("newPassword", {
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters"
                  }
                })}
              />
              {newPasswordErrors.newPassword && (
                <StyledInputErrorMessage>
                  {newPasswordErrors.newPassword.message}
                </StyledInputErrorMessage>
              )}
            </InputAndErrorWrapper>
            <InputAndErrorWrapper>
              <StyledTextInput
                labelText='Verify Password'
                type='password'
                id='verifyPassword'
                name='verifyPassword'
                placeholder='Verify Password'
                {...registerChangePasswordForm("verifyPassword", {
                  validate: (value) =>
                    value === getPasswordChangeValues("newPassword") ||
                    "Does not match new password"
                })}
              />
              {newPasswordErrors.verifyPassword && (
                <StyledInputErrorMessage>
                  {newPasswordErrors.verifyPassword.message}
                </StyledInputErrorMessage>
              )}
            </InputAndErrorWrapper>
            <StyledButton
              primary
              disabled={
                newPasswordFieldValue == null ||
                verifyPasswordFieldValue == null ||
                newPasswordFieldValue?.length === 0 ||
                verifyPasswordFieldValue?.length === 0
              }
            >
              Update
            </StyledButton>
          </InnerFormContainer>
        </StyledForm>
      </Container>
      <Modal
        closeButtonHidden
        disableCloseOnOutsideClick
        title='Current Password'
        show={showEnterCurrentPassword}
        onClose={closeModal}
      >
        <Container>
          <InnerFormContainer>
            <InputAndErrorWrapper>
              <StyledTextInput
                labelText='Current Password'
                type='password'
                id='currentPassword'
                name='currentPassword'
                placeholder='Current Password'
                ref={currentPasswordRef}
              />
            </InputAndErrorWrapper>
            <Button onClick={reauthenticate}>Submit</Button>
          </InnerFormContainer>
        </Container>
      </Modal>
      <Loading isLoading={showLoading} />
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const InnerFormContainer = styled.div`
  /* margin-bottom: 25px; */
  padding: 10px;
  min-width: 300px;
  /* border: 1px solid ${COLORS.secondary}; */
  /* border-radius: ${CONSTANTS.borderRadius}; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerFormTitle = styled.div`
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Divider = styled.div`
  height: 1px;
  margin: 15px 10px;
  background-color: ${COLORS.text};
`;

const InputAndErrorWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const StyledTextInput = styled(LabelledTextInput)``;

const StyledInputErrorMessage = styled.div`
  padding: 3px 0 0 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  align-self: flex-end;
`;

export default EditLoggedInUser;
