import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import ColorCard from "components/ColorCard";
import useProjectArticles from "./useProjectArticles";
import useNavigation from "helpers/useNavigation";

export const ProjectViewerPage = () => {
  const { projID } = useParams();
  const [navigate] = useNavigation();

  const [project, setProject] = useState();

  const selectedItems = useMemo(() => {
    return project?.selectedItems?.map((item) => item.id);
  }, [project?.selectedItems]);

  const { articlesData, currentInventory, currentInventoryLoading } =
    useProjectArticles(
      selectedItems,
      project?.warehouses,
      project?.articleSearchKey,
    );

  useEffect(() => {
    const db = getFirestore();
    getDoc(doc(db, "projects", projID)).then((doc) => {
      if (!doc.exists()) {
        navigate("/public/projects");
      }
      setProject({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt.toDate(),
        modifiedAt: doc.data().modifiedAt.toDate(),
      });
    });
  }, [navigate, projID]);

  return (
    <div className="grid h-full w-full grid-rows-[auto_1fr_auto] overflow-hidden">
      <div
        // className={`flex max-h-20 w-full items-center gap-5 p-2 shadow-[0px_4px_6px_4px_${COLORS.stormtechOrange}] z-10`}
        className={`z-10 flex max-h-20 w-full items-center justify-between gap-5 p-2 shadow-md md:justify-start`}
      >
        <img
          className="h-full max-w-[150px] object-contain md:max-w-[250px]"
          src={project?.logoURL || "/stormtech_icon_placeholder.svg"}
          alt="logo"
        />
        <div className="flex flex-col">
          <div className="text-base font-bold text-text">{project?.name}</div>
          <div className="text-sm text-text">{project?.distributor}</div>
          <div className="text-sm font-extralight text-text">
            {project?.rep !== "xxxx" && project?.rep}
          </div>
        </div>
      </div>
      <div className="z-0 flex h-full flex-col items-center gap-3 overflow-auto bg-secondary p-3 pb-8">
        {articlesData.map((item) =>
          item.colors.map((color) => (
            <ColorCard
              key={color.id}
              productData={color}
              showOversold={false}
              showItemNumber={true}
              selectedWarehouses={project?.warehouses}
              currency={project?.currency}
              liveInventory={currentInventory}
              isLoadingInventory={currentInventoryLoading}
            />
          )),
        )}
      </div>
      <div className="flex h-6 w-full justify-center border-t border-st-orange py-1">
        <img
          className="h-full object-contain"
          src="/stormtech_horizontal.svg"
          alt="logo"
        />
      </div>
    </div>
  );
};
