import React from "react";

const ProjectCard = ({ projectData, onClick }) => {
  return (
    <div
      className="grid cursor-pointer grid-rows-[auto_auto] rounded-md border border-secondary bg-white p-2 hover:border-st-orange"
      onClick={onClick}
    >
      <div className="flex items-center gap-5 border-b border-b-secondary">
        <div className="flex h-[80px] w-[100px] items-center justify-center p-1">
          <img
            className="max-h-full max-w-full object-contain"
            src={projectData.logoURL || "/stormtech_icon_placeholder.svg"}
            alt="proj"
          />
        </div>
        <div className="flex flex-col items-start">
          <div className="text-base font-bold text-text">
            {projectData?.name}
          </div>
          <div className="text-sm text-text">
            {projectData?.distributor !== "xxxx"
              ? projectData?.distributor
              : ""}
          </div>
          <div className="text-sm font-extralight text-text">
            {projectData?.rep !== "xxxx" ? projectData?.rep : ""}
          </div>
        </div>
      </div>
      <div>
        <div className="flex w-full justify-end">
          <div className="pr-2 text-sm font-extralight text-text">Created:</div>
          <div className="text-sm font-light text-text">
            {projectData.createdAt.toLocaleString("en-CA", {
              dateStyle: "medium",
              timeStyle: "short",
            })}
          </div>
        </div>
        <div className="flex w-full justify-end">
          <div className="pr-2 text-sm font-extralight text-text">Edited:</div>
          <div className="text-sm font-light text-text">
            {projectData.modifiedAt.toLocaleString("en-CA", {
              dateStyle: "medium",
              timeStyle: "short",
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
