import { createContext, useContext, useEffect, useState } from "react";
import { getSearchClient } from "services/typesense";
import { useDatabaseReader } from "database/useDatabaseReader";
import { Outlet, useSearchParams } from "react-router-dom";

const ProjectSearchContext = createContext();

const ProjectSearchContextProvider = () => {
  const [searchParams] = useSearchParams();
  const { projectSearchKey } = useDatabaseReader();
  const [typesenseClient, setTypesenseClient] = useState();
  const [searchTerm, setNewSearchTerm] = useState("");
  const [filters, setFilters] = useState({});
  const [currentFacetSearch, setCurrentFacetSearch] = useState(null);
  const [sortBy, setSort] = useState(
    localStorage.getItem("sort") || "modifiedAt:desc",
  );
  const [searchResults, setSearchResults] = useState({
    facetCounts: [],
    projects: [],
  });

  useEffect(() => {
    if (projectSearchKey == null) return;
    setTypesenseClient(getSearchClient(projectSearchKey));
  }, [projectSearchKey]);

  const setSortBy = (sortBy) => {
    localStorage.setItem("sort", sortBy);
    setSort(sortBy);
  };

  useEffect(() => {
    if (typesenseClient == null) return;
    let constructedFilters = "";
    Object.entries(filters).forEach(([field, value], idx) => {
      if (value == null || value.length === 0) return;
      constructedFilters += `${
        constructedFilters.length === 0 ? "" : "&&"
      }${field}:=[${value.join(",")}]`;
    });

    typesenseClient
      .collections(
        ["local"].includes(process.env.REACT_APP_USER_ENVIRONMENT)
          ? "staging.projects"
          : "production.projects",
      )
      .documents()
      .search({
        q: searchTerm && searchTerm.length > 0 ? searchTerm : "*",
        query_by: "name",
        filter_by: constructedFilters,
        ...(searchParams.get("d")?.length > 0 && {
          hidden_hits: searchParams.get("d"),
        }),
        facet_by: "distributor, rep",
        ...(currentFacetSearch != null && {
          facet_query: `${currentFacetSearch.field}:${currentFacetSearch.searchTerm}`,
        }),
        max_facet_values: 30,
        per_page: 50,
        sort_by: sortBy,
      })
      .then((results) => {
        setSearchResults({
          facetCounts: results.facet_counts,
          projects: results.hits.map((hit) => ({
            ...hit.document,
            createdAt: new Date(hit.document.createdAt),
            modifiedAt: new Date(hit.document.modifiedAt),
          })),
        });
      });
  }, [
    searchTerm,
    filters,
    currentFacetSearch,
    sortBy,
    typesenseClient,
    searchParams,
  ]);

  const toggleFilter = (field, value) => {
    const newFilters = { ...filters };
    if (newFilters[field] == null) {
      newFilters[field] = [value];
    } else {
      if (newFilters[field].includes(value)) {
        newFilters[field] = newFilters[field].filter((v) => v !== value);
      } else {
        newFilters[field].push(value);
      }
    }
    setFilters(newFilters);
  };

  const setSearchTerm = (term) => {
    setNewSearchTerm(term);
    setCurrentFacetSearch(null);
  };

  return (
    <ProjectSearchContext.Provider
      value={{
        searchResults,
        searchTerm,
        setSearchTerm,
        sortBy,
        setSortBy,
        filters,
        toggleFilter,
        currentFacetSearch,
        setCurrentFacetSearch,
      }}
    >
      <Outlet />
    </ProjectSearchContext.Provider>
  );
};

export default ProjectSearchContextProvider;

export const useProjectSearch = () => useContext(ProjectSearchContext);
