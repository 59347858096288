export const COLORS = {
  stormtechOrange: "#F18A00",
  stormtechGrey: "#54555A",
  text: "gray",
  pageBackground: "white",
  primary: "black",
  primaryInverted: "white",
  primaryHover: "rgba(75, 75, 75, 1.0)",
  //primaryHover: "gray",
  secondary: "lightgray",
  tertiary: "WhiteSmoke",
  darkButton: "Gainsboro",
  linkPrimary: "gray",
  green: "rgb(57,181,74)",
  alertOrange: "rgb(247, 147, 30)",
  alertRed: "rgb(204, 0, 0)",
  select: "rgb(0, 153, 51)",
  clear: "transparent"
};

export const CONSTANTS = {
  borderRadius: "8px"
};

export const FONTSIZE = {
  xsmall: "12px",
  small: "15px",
  medium: "18px",
  large: "22px",
  xlarge: "35px"
};

export const FONTWEIGHT = {
  light: 100,
  medium: 400,
  heavy: 700
};
