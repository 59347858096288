import { createContext, useContext, useEffect, useState } from "react";

import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

import { useAuth } from "./useAuth";
import { Outlet } from "react-router-dom";

//Create Context
const DatabaseReaderContext = createContext();

//Context Provider
const DatabaseReaderContextProvider = ({ children }) => {
  //HOOKS
  const { authContextLoaded, user, tooManyDevices } = useAuth();

  //STATE
  const [articleSearchKey, setArticleSearchKey] = useState();
  const [projectSearchKey, setProjectSearchKey] = useState();
  const [userSearchKey, setUserSearchKey] = useState();
  const [userRegion, setUserRegion] = useState();
  const [warehouses, setWarehouses] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [preferredCurrency, setPreferredCurrency] = useState("$ CAD");

  useEffect(() => {
    if (!authContextLoaded || user == null) {
      setArticleSearchKey();
      return;
    }

    (async () => {
      const userDoc = await getDoc(doc(getFirestore(), "users", user.uid));
      const region = userDoc.data().region;
      setUserRegion(userDoc.data().region);
      setPreferredCurrency(userDoc.data().preferredCurrency || "$ CAD");

      const configDoc = await getDoc(
        doc(getFirestore(), "configuration", "configV2"),
      );

      //Filtering this way to follow the warehoue ordering in the warehouses section of the config doc
      const warehousesInRegion = configDoc
        .data()
        .warehouses.filter((warehouse) => {
          return configDoc
            .data()
            .regions[region].includes(warehouse.letterCode);
        });
      setWarehouses(warehousesInRegion);

      setCurrencyOptions(configDoc.data().currencyOptions);

      //Set the Articles search keys based on the user environment
      if (["local"].includes(process.env.REACT_APP_USER_ENVIRONMENT)) {
        setArticleSearchKey(configDoc.data().typesense.articles.staging);
      } else {
        setArticleSearchKey(configDoc.data().typesense.articles.production);
      }

      //Set the User search keys based on the user environment
      //Production environment is the same for both staging and production
      if (process.env.REACT_APP_USER_ENVIRONMENT === "local") {
        setUserSearchKey(configDoc.data().typesense.users.staging);
      } else {
        setUserSearchKey(configDoc.data().typesense.users.production);
      }

      //Set the Project search keys based on the user environment
      //Production environment is the same for both staging and production
      if (process.env.REACT_APP_USER_ENVIRONMENT === "local") {
        setProjectSearchKey(userDoc.data().typesense?.projects?.staging);
      } else {
        setProjectSearchKey(userDoc.data().typesense?.projects?.production);
      }
    })();
  }, [authContextLoaded, tooManyDevices, user]);

  const changePreferredCurrency = async (newCurrency) => {
    setPreferredCurrency(newCurrency);
    await updateDoc(doc(getFirestore(), "users", user.uid), {
      preferredCurrency: newCurrency,
    });
  };

  //Share variables and methods to children through the provider
  return (
    <DatabaseReaderContext.Provider
      value={{
        articleSearchKey,
        userSearchKey,
        projectSearchKey,
        warehouses,
        userRegion,
        currencyOptions,
        preferredCurrency,
        changePreferredCurrency,
      }}
    >
      {/* {children} */}
      <Outlet />
    </DatabaseReaderContext.Provider>
  );
};

export default DatabaseReaderContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useDatabaseReader = () => useContext(DatabaseReaderContext);
