import React from "react";

export const NotFound = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 bg-secondary">
      <div className="flex flex-col items-center pb-2">
        <img
          src="/stormtech.svg"
          alt="logo"
          className="mb-2 w-[75vw] md:w-[300px]"
        />
      </div>
      <div className="flex flex-col items-center">
        <p className="text-4xl font-bold text-st-grey">404</p>
        <p className="text-st-grey">
          The page you are looking for cannot be found
        </p>
      </div>
    </div>
  );
};
