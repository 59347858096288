import LandingPage from "pages/landingPage/LandingPage";
import { useAuth } from "database/useAuth";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { user, authContextLoaded } = useAuth();

  return !authContextLoaded ? (
    // <Outlet>
    <LandingPage shouldRedirect={false} />
  ) : // </Outlet>
  authContextLoaded && user != null ? (
    <Outlet />
  ) : (
    <Navigate to='/' replace />
  );
};

export default ProtectedRoute;
