import Modal from "components/Modal";
import SearchWindow from "components/searchWindow/SearchWindow";
import { useDatabaseReader } from "database/useDatabaseReader";
import React from "react";

const ProductPickerModal = ({
  isOpen,
  onClose,
  selectedItems,
  onSelectItem,
}) => {
  const { warehouses } = useDatabaseReader();

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      title="Product Search"
      contentPadding={"0px"}
    >
      <div className="h-full w-full overflow-hidden rounded-bl-md rounded-br-md min-[550px]:h-[80vh] min-[550px]:w-[85vw] min-[1150px]:w-[1100px]">
        <SearchWindow
          selectedWarehouses={warehouses}
          isSelectable
          selectedItems={selectedItems}
          onSelectItem={onSelectItem}
        />
      </div>
    </Modal>
  );
};

export default ProductPickerModal;
