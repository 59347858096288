import React, { forwardRef } from "react";

import styled from "styled-components/macro";
import { TextInput } from "components/standardElements";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";

//PROPS: labelText, type, id, name, placeholder, marginBottom, className
const LabelledTextInput = forwardRef(
  (
    {
      labelText,
      type,
      id,
      name,
      capitalize,
      placeholder,
      marginBottom,
      className,
      shouldAutoFocus = false,
      value,
      ...props
    },
    ref /*from react-hook-forms*/,
  ) => {
    return (
      <Wrapper className={className}>
        <StyledLabel htmlFor={id}>{labelText}</StyledLabel>
        <StyledTextInput
          {...props}
          autoFocus={shouldAutoFocus}
          marginBottom={marginBottom}
          type={type}
          id={id}
          name={name}
          value={value}
          capitalize={capitalize}
          placeholder={placeholder}
          ref={ref}
          {...props}
        ></StyledTextInput>
      </Wrapper>
    );
  },
);

LabelledTextInput.defaultProps = {
  type: "text",
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label`
  text-transform: uppercase;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding-left: 5px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : null)};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
  border: 1px solid ${COLORS.secondary};
`;

export default LabelledTextInput;
