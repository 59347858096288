import React, { useState } from "react";
import { useDatabaseReader } from "database/useDatabaseReader";
import StandardLayout from "components/StandardLayout";
import LeftMenu from "./leftMenu/LeftMenu";
import SearchWindow from "components/searchWindow/SearchWindow";

const SearchPage = () => {
  const { warehouses } = useDatabaseReader();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const onSelectedWarehouse = (selection) => {
    setIsMenuOpen(false);
    setSelectedWarehouse(selection);
  };

  return (
    <StandardLayout
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      leftMenuContent={
        <LeftMenu
          selectedWarehouse={selectedWarehouse}
          onSelectedWarehouse={onSelectedWarehouse}
          onMenuClose={setIsMenuOpen}
        />
      }
    >
      <SearchWindow
        selectedWarehouses={
          selectedWarehouse != null ? [selectedWarehouse] : warehouses
        }
      />
    </StandardLayout>
  );
};

export default SearchPage;
